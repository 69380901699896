/* eslint-disable no-undef */
import React, { Fragment, useRef, useState, useEffect } from 'react';
import propTypes from 'prop-types';

import MobileMenuHeader from './MobileMenuHeader';
import MobileMenuContent from './MobileMenuContent';
import MobileMenuFooter from './MobileMenuFooter';

const MobileMenuLevel2 = ({ closeMenu, content, toggleMobileMenuLevel2, toggleMobileMenuLevel3, shop, toggleSearch }) => {
    const container = useRef(null);
    const [brandScroll, setBrandScroll] = useState(null);
    const [activeLetter, setActiveLetter] = useState(null);


    useEffect(() => {
        if (container && brandScroll) {
            container.current.scrollTo({ behavior: 'smooth', 'top': brandScroll.offsetTop - 10 });
            setBrandScroll(null);
        }
    }, [brandScroll]);

    const getNextLevel = (menuContent, featuredLinks = false) => {
        toggleMobileMenuLevel3(menuContent, true, featuredLinks);
    };

    const scrollToLetter = (letter) => {
        if (!container) {
            return;
        }
        const elementToScrollTo = document.getElementsByClassName(`brand-section-header-${letter}`)[0];
        setActiveLetter(letter);
        setBrandScroll(elementToScrollTo);
    };

    return (
        <div className='mobile-menu' onClick={closeMenu}>
            <div ref={container} className='menu-container' onClick={(e) => e.stopPropagation()}>
                <MobileMenuHeader toggleMobileMenuLevel={toggleMobileMenuLevel2} shop={shop} toggleSearch={toggleSearch} />
                {
                    content &&
                    <Fragment>
                        <MobileMenuContent closeMenu={closeMenu} content={content} getNextLevel={getNextLevel} scrollToLetter={scrollToLetter} activeLetter={activeLetter}/>
                        {(content[0].edited || !shop) && <MobileMenuFooter edited={ content[0].edited} shop={shop} />}
                    </Fragment>
                }
            </div>
        </div>
    );
};

MobileMenuLevel2.propTypes = {
    closeMenu: propTypes.func.isRequired,
    content: propTypes.array,
    toggleMobileMenuLevel2: propTypes.func.isRequired,
    toggleMobileMenuLevel3: propTypes.func.isRequired,
    toggleSearch: propTypes.func.isRequired,
    shop: propTypes.bool
};

export default MobileMenuLevel2;