import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Anchor } from 'shared/components/Anchor/Anchor';
import DesktopMenuContentEdited from './DesktopMenuContentEdited';

const DesktopMenuContentShop = ({ t, menuContent, closeMenu, menuTransition, setMenuTransition }) => {
    const buildColumn = (columnNumber) => {
        return menuContent.items.map((section, sectionIndex) => {
            if (section.column === columnNumber) {
                const headerTrackingData = { event: 'top-menu-nav', eventCategory: section.title, action: section.title, label: section.title };

                return (
                    <div key={sectionIndex} className='desktop-menu-content__section'>
                        <Anchor
                            trackingData={headerTrackingData}
                            className='desktop-menu-content__section--title'
                            url={section.link}
                            key={sectionIndex}
                            onClick={() => closeMenu()}
                        >
                            {section.title}
                        </Anchor>
                        {section.level &&
                            section.level[0].items.map((item, itemIndex) => {
                                const sectionItemTrackingData = { event: 'top-menu-nav', eventCategory: section.title, action: section.title, label: item.title };

                                return (
                                    <Anchor
                                        trackingData={sectionItemTrackingData}
                                        className='desktop-menu-content__section--link'
                                        url={item.link}
                                        key={itemIndex}
                                        onClick={() => closeMenu()}
                                    >
                                        {item.title}
                                    </Anchor>
                                );
                            })}
                    </div>
                );
            }
        });
    };

    useEffect(() => {
        setMenuTransition(true);
    }, [menuContent]);


    return (
        <div className={`desktop-menu-content__container ${menuTransition ? 'active' : ''}`}>
            <div className='desktop-menu-content__column'>
                <div
                    className='desktop-menu-content__featured-section'
                    style={
                        menuContent.accentColour && {
                            background: `${menuContent.accentColour}`,
                        }
                    }
                >
                    {menuContent.viewAllLink && (
                        <Anchor
                            className='shop-all-link'
                            url={menuContent.viewAllLink}
                            onClick={() => closeMenu()}
                        >
                            {t('header:shopAll')}
                        </Anchor>
                    )}
                    {menuContent && menuContent.featuredLinks && menuContent.featuredLinks.map(
                        (featuredLink, featuredLinkIndex) => {
                            return (
                                <Anchor
                                    className='featured-link'
                                    key={featuredLinkIndex}
                                    url={featuredLink.link}
                                    onClick={() => closeMenu()}
                                >
                                    {featuredLink.title}
                                </Anchor>
                            );
                        }
                    )}
                </div>
                {buildColumn(1)}
            </div>
            <div className='desktop-menu-content__column'>
                {buildColumn(2)}
            </div>
            <div className='desktop-menu-content__column'>
                {buildColumn(3)}
            </div>
            <div className='desktop-menu-content__column'>
                {buildColumn(4)}
            </div>
            <div className='desktop-menu-content__column'>
                {buildColumn(5)}
            </div>
            <div className='desktop-menu-content__column'>
                {menuContent.edited && <DesktopMenuContentEdited editedContent={menuContent.edited} closeMenu={closeMenu} />}
            </div>
        </div>
    );
};

DesktopMenuContentShop.propTypes = {
    t: propTypes.func.isRequired,
    menuContent: propTypes.object.isRequired,
    closeMenu: propTypes.func.isRequired,
    setMenuTransition: propTypes.func.isRequired,
    menuTransition: propTypes.bool.isRequired
};

export default withTranslation(['header'])(DesktopMenuContentShop);
