/* eslint-disable no-undef */
import React from 'react';
import propTypes from 'prop-types';

import SearchWrapper from '../../../search/SearchWrapper';

import '../../styles/shared/HeaderSearch.scss';

const HeaderSearch = ({ user, history, closeMenu, getSearchLinksData, focusSearch, mobile, getAutocompleteOptions }) => {
    return (
        <div className='header-search' onClick={closeMenu}>
            <SearchWrapper user={user} history={history} closeMenu={closeMenu} getSearchLinksData={getSearchLinksData} autofocus={focusSearch} mobile={mobile} getAutocompleteOptions={getAutocompleteOptions} />
        </div>
    );
};

HeaderSearch.propTypes = {
    history: propTypes.object,
    user: propTypes.object,
    mobile: propTypes.bool,
    closeMenu: propTypes.func.isRequired,
    getSearchLinksData: propTypes.func.isRequired,
    focusSearch: propTypes.bool,
    getAutocompleteOptions: propTypes.func.isRequired
};

export default HeaderSearch;