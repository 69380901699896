import React from 'react';
import propTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'shared/svg/close.svg';
import classname from 'classnames';

import DesktopMenuContentBrands from '../desktop/DesktopMenuContentBrands';
import DesktopMenuContentShop from '../desktop/DesktopMenuContentShop';

import '../../styles/desktop/DesktopMenuContent.scss';

const DesktopMenuContent = ({ menuContent, closeMenu, menuTransition, setMenuTransition, headerMenuRef, headerRef }) => {

    const menuContentsClassName = classname({
        'desktop-menu-contents': true
    });

    return (
        <div className={menuContentsClassName} onClick={() => closeMenu()}>
            <div className='desktop-menu-contents__container'>
                <div className='desktop-menu-content' onClick={(e) => e.stopPropagation()}>
                    {menuContent && menuContent.brands && <DesktopMenuContentBrands menuContent={menuContent} closeMenu={closeMenu} menuTransition={menuTransition} setMenuTransition={setMenuTransition} headerMenuRef={headerMenuRef} headerRef={headerRef}/>}
                    {menuContent && !menuContent.brands && <DesktopMenuContentShop menuContent={menuContent} closeMenu={closeMenu} menuTransition={menuTransition} setMenuTransition={setMenuTransition}/>}
                </div>
                <div className='desktop-menu-contents__close' onClick={() => closeMenu()}>
                    <CloseIcon className='icon close-icon' />
                </div>
            </div>
        </div>
    );
};

DesktopMenuContent.propTypes = {
    menuContent: propTypes.object,
    headerMenuRef: propTypes.object,
    headerRef: propTypes.object,
    closeMenu: propTypes.func.isRequired,
    setMenuTransition: propTypes.func.isRequired,
    menuTransition: propTypes.bool.isRequired
};

export default DesktopMenuContent;
