import React, { Fragment } from 'react';
import propTypes from 'prop-types';

import '../../styles/shared/BrandAlphabet.scss';

const BrandAlphabet = ({ brands, scrollToLetter }) => {
    const handleClick = (event, letter) => {
        event.stopPropagation();

        if (brands[letter].length === 0) {
            return;
        }
        scrollToLetter(letter);
    };

    return (
        <Fragment>
            <div className='brand-alphabet'>
                {brands && Object.keys(brands).map((letter) => {
                    return <div key={letter} className={`brand-letter ${brands[letter].length === 0 ? 'inactive-letter' : ''}`} onClick={(event) => handleClick(event, letter)}>{letter}</div>;
                })}
            </div>
        </Fragment>
    );
};

BrandAlphabet.propTypes = {
    brands: propTypes.object.isRequired,
    scrollToLetter: propTypes.func.isRequired
};

export default BrandAlphabet;