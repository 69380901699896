import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';

import Usp from '../../components/shared/Usp';
import InformationMenuLinks from './InformationMenuLinks';
import InformationMenuContent from './InformationMenuContent';
import { CSSTransition } from 'react-transition-group';
import { Anchor } from 'shared/components/Anchor/Anchor';

import '../../styles/desktop/DesktopHeaderInformationBar.scss';

const DesktopHeaderInformationBar = ({ getCountries, userData, getUsp, setUspVisible, setFixedUspVisible, menuContent, closeMenu, location }) => {
    const [country, setCountry] = useState({});
    const [infoBarContent, setInfoBarContent] = useState({
        show: false,
        selectedOption: ''
    });
    const [infoTransition, setInfoTransition] = useState(true);
    const [userHeaderData, setUserHeaderData] = useState({});

    useEffect(() => {
        setUserHeaderData(userData);
    }, [userData]);

    useEffect(() => {
        if(userHeaderData.countryCode) {
            getCountries()
                .then(countries => {
                    let userCountry = countries.find(c => c.countryCode === userHeaderData.countryCode);
                    if (!userCountry) {
                        userCountry = countries.find(c => c.countryCode === 'GB');
                    }
                    setCountry(userCountry);
                });
        }
    }, [userHeaderData]);


    // TODO - move state handling to DesktopHeader.jsx
    const toggleInformationMenu = (selectedOption) => {
        closeMenu();
        setInfoTransition(false);
        setInfoBarContent({
            show: (true && !selectedOption.length) || (true && (selectedOption === infoBarContent.selectedOption)) ? false : true,
            selectedOption: selectedOption === infoBarContent.selectedOption ? '' : selectedOption
        });
    };

    const currencySymbol = country.currencySymbol ? `(${country.currencySymbol})` : '';

    return (
        <div className={`desktop-header__info-bar ${infoBarContent.show ? 'menu-open' : ''}`}>
            <div className='desktop-header__info-bar--content'>
                <div className='desktop-header__info-bar--site-preferences'>
                    <Anchor url={`/country-selection/?returnUrl=${location.pathname}`} trackingData={{ event: 'SiteFlagClick' }}>
                        <div className='site-preferences'>
                            {
                                country.currency && 
                                <span className='site-preferences__region-info'>{`${country.currency} ${currencySymbol} ${userHeaderData.language ? userHeaderData.language.toUpperCase() : ''}`}</span>
                            }
                            {country.flag && 
                                <span className='site-preferences__flag'>
                                    <img src={country.flag.imageUrl} alt={`${country.countryCode} - Flag`} />
                                </span>
                            }
                        </div>
                    </Anchor>
                </div>
                <div className='desktop-header__info-bar--usp'>
                    <Usp headerApi={{ getUsp: getUsp }} setUspVisible={setUspVisible} setFixedUspVisible={setFixedUspVisible} />
                </div>
                <InformationMenuLinks toggleInformationMenu={toggleInformationMenu} selectedOption={infoBarContent.selectedOption} />
            </div>
            <CSSTransition in={infoBarContent.show} timeout={{ exit: 200 }} unmountOnExit classNames='info-bar-transition'>
                <div>
                    <InformationMenuContent selectedOption={infoBarContent.selectedOption} menuContent={menuContent} closeMenu={toggleInformationMenu} infoTransition={infoTransition} setInfoTransition={setInfoTransition} />
                </div>
            </CSSTransition>
        </div>
    );
};

DesktopHeaderInformationBar.propTypes = {
    userData: propTypes.object.isRequired,
    getCountries: propTypes.func.isRequired,
    getUsp: propTypes.func.isRequired,
    setUspVisible: propTypes.func.isRequired,
    setFixedUspVisible: propTypes.func.isRequired,
    menuContent: propTypes.object.isRequired,
    closeMenu: propTypes.func.isRequired,
    location: propTypes.object
};

export default DesktopHeaderInformationBar;