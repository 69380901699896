import { useAuth0 } from '@auth0/auth0-react';
import { fetchClass } from 'api/lib/fetch';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const AuthContext = React.createContext({ ready: false, user: null });

export const AuthProvider = ({ children }) => {
    const { getAccessTokenSilently, isLoading } = useAuth0();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            fetchClass.setTokenGenerator(getAccessTokenSilently);
            setReady(true);
        }
    }, [getAccessTokenSilently, isLoading]);

    return (
        <AuthContext.Provider value={{ ready }}>
            {ready ? children : null}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: propTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
