/* eslint-disable no-undef */
import React from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Anchor } from 'shared/components/Anchor/Anchor';

const MobileMenuContentLevel3 = ({ t, content, featuredLinks }) => {

    return (
        <div className='menu-container__main'>
            {
                featuredLinks ?
                    <div className='menu-container__main--section' >
                        <div className='menu-container__main--section-header'>
                            <h3>{t('header:featured')}</h3>
                        </div>
                        {
                            content.map((item, itemIndex) => {
                                const trackingDataFeaturedLink = { event: 'mobileMenuLink', category: 'Mobile Menu - Featured Link', action: 'Mobile Menu Click', label: item.title };

                                return (
                                    <Anchor trackingData={trackingDataFeaturedLink} key={itemIndex} url={item.link}>{item.title}</Anchor>
                                );
                            })
                        }
                    </div>
                    :
                    content.map((section, index) => {
                        return (
                            <div className='menu-container__main--section' key={index}>
                                <div className={`menu-container__main--section-header ${section.image ? 'with-image' : ''}`}>
                                    <h3>{section.title}</h3>
                                    {
                                        section.image &&
                                        <img src={section.image} alt={section.title} />
                                    }
                                </div>
                                {
                                    section.viewAllLink &&
                                    <Anchor className='view-all-link' url={section.viewAllLink}>{t('header:shopAll')}</Anchor>
                                }
                                {
                                    section.items.map((item, itemIndex) => {
                                        const trackingDataLink = { event: 'mobileMenuLink', category: 'Mobile Menu - Link', action: 'Mobile Menu Click', label: item.title };

                                        return (
                                            <Anchor trackingData={trackingDataLink} key={itemIndex} url={item.link}>{item.title}</Anchor>
                                        );
                                    })
                                }
                            </div>
                        );
                    })
            }
        </div>
    );
};

MobileMenuContentLevel3.propTypes = {
    t: propTypes.func.isRequired,
    content: propTypes.array.isRequired,
    featuredLinks: propTypes.bool.isRequired
};

export default withTranslation(['header'])(MobileMenuContentLevel3);