import React, { useState, useRef } from 'react';
import propTypes from 'prop-types';
import { HeaderConsumer } from '../../headerContext';
import { CSSTransition } from 'react-transition-group';
import classname from 'classnames';

import DesktopHeaderInformationBar from './components/desktop/DesktopHeaderInformationBar';
import DesktopHeaderMain from './components/desktop/DesktopHeaderMain';
import DesktopHeaderMenu from './components/desktop/DesktopHeaderMenu';
import DesktopMenuContent from './components/desktop/DesktopMenuContent';
import HeaderSearch from './components/shared/HeaderSearch';

import './styles/desktop/DesktopHeader.scss';

const DesktopHeader = ({ setUspVisible, setFixedUspVisible, getUsp, getCountries, history, getSearchLinksData, isOnBagPage, location, setBrandsOpen, user, getAutocompleteOptions }) => {

    const headerMenuRef = useRef(null);
    const headerRef = useRef(null);

    const [searchMenu, setSearchMenu] = useState({
        focusSearch: true,
        showSearch: false
    });

    const [desktopMenu, setDesktopMenu] = useState({
        showMenu: false,
        menuContent: null,
        activeItem: null
    });

    const [menuTransition, setMenuTransition] = useState(true);

    const toggleSearch = () => {
        setSearchMenu({
            showSearch: searchMenu.showSearch ? false : true,
        });
        closeMenu();
    };

    const closeMenu = () => {
        setDesktopMenu({
            showMenu: false,
            menuContent: null,
            activeItem: null
        });
        setBrandsOpen(null);
    };

    const toggleMenu = (menuContent, activeItem) => {
        setDesktopMenu({
            showMenu: (desktopMenu.showMenu && activeItem === desktopMenu.activeItem) ? false : true,
            menuContent: (menuContent && menuContent !== desktopMenu.menuContent) ? menuContent : null,
            activeItem: (activeItem === desktopMenu.activeItem || !activeItem) ? null : activeItem
        });
        setBrandsOpen((activeItem === desktopMenu.activeItem || !activeItem) ? null : activeItem);
    };

    const hoverSwitchMenu = (menuContent, activeItem) => {
        setMenuTransition(false);
        setDesktopMenu({
            showMenu: true,
            menuContent: menuContent,
            activeItem: (activeItem === desktopMenu.activeItem || !activeItem) ? desktopMenu.activeItem : activeItem
        });
        setBrandsOpen(activeItem);
    };

    const desktopHeaderClassNames = classname({
        'header-wrapper': true,
        'desktop-header': true,
        'open': desktopMenu.showMenu
    });

    return (
        <HeaderConsumer>
            {
                ({ bag, wishlist, menuContent }) => (

                    menuContent &&
                    <header id='beautybay-header' className={desktopHeaderClassNames} ref={headerMenuRef}>
                        <div ref={headerRef}>
                            <DesktopHeaderInformationBar
                                getCountries={getCountries}
                                userData={user}
                                setUspVisible={setUspVisible}
                                setFixedUspVisible={setFixedUspVisible}
                                getUsp={getUsp}
                                menuContent={menuContent}
                                closeMenu={closeMenu}
                                location={location}
                            />
                            <DesktopHeaderMain toggleSearch={toggleSearch} user={user} bag={bag} wishlist={wishlist} isOnBagPage={isOnBagPage} />
                            <DesktopHeaderMenu menuContent={menuContent} toggleMenu={toggleMenu} hoverSwitchMenu={hoverSwitchMenu} activeItem={desktopMenu.activeItem} />
                        </div>
                        <CSSTransition in={searchMenu.showSearch} timeout={{ exit: 200 }} unmountOnExit classNames='search-transition'>
                            <div>
                                <div className='desktop-header__search--content'>
                                    <HeaderSearch user={user} history={history} closeMenu={toggleSearch} getSearchLinksData={getSearchLinksData} mobile={false} getAutocompleteOptions={getAutocompleteOptions} />
                                </div>
                            </div>
                        </CSSTransition>
                        <CSSTransition in={desktopMenu.showMenu} timeout={{ exit: 200 }} unmountOnExit classNames='desktop-menu-transition'>
                            <div>
                                <DesktopMenuContent menuContent={desktopMenu.menuContent} closeMenu={closeMenu} menuTransition={menuTransition} setMenuTransition={setMenuTransition} headerMenuRef={headerMenuRef} headerRef={headerRef} />
                            </div>
                        </CSSTransition>
                    </header>
                )}
        </HeaderConsumer>
    );
};

DesktopHeader.propTypes = {
    setUspVisible: propTypes.func,
    setFixedUspVisible: propTypes.func,
    getUsp: propTypes.func,
    getCountries: propTypes.func.isRequired,
    getSearchLinksData: propTypes.func.isRequired,
    history: propTypes.object.isRequired,
    user: propTypes.object.isRequired,
    isOnBagPage: propTypes.bool.isRequired,
    location: propTypes.object,
    setBrandsOpen: propTypes.func.isRequired,
    getAutocompleteOptions: propTypes.func.isRequired
};

export default DesktopHeader;