/* eslint-disable no-undef */
import React, { Fragment } from 'react';
import propTypes from 'prop-types';

import { Anchor } from 'shared/components/Anchor/Anchor';
import { ReactComponent as EditedLogo } from 'shared/svg/edited-logo.svg';
import CompanyInfo from '../shared/CompanyInfo';

const MobileMenuFooter = ({ edited, shop }) => {

    return (
        <div className='menu-container__footer'>
            {edited &&
                <div className='menu-container__footer--edited'>
                    <div className='menu-container__footer--edited-header'>
                        <h3>{edited.title}</h3>
                        <EditedLogo className='edited-logo' />
                    </div>
                    <div className='menu-container__footer--edited-main'>
                        {edited.items.map((item, itemIndex) => {
                            const trackingData = { event: 'mobileMenuLink', category: 'Mobile Menu - Edited Link', action: 'Mobile Menu Click', label: item.title };

                            return (
                                <Anchor trackingData={trackingData} key={itemIndex} url={item.link}>{item.title}</Anchor>
                            );
                        })}
                    </div>
                </div>
            }
            {!shop &&
                <Fragment>
                    <CompanyInfo />
                </ Fragment>
            }
        </div>
    );
};

MobileMenuFooter.propTypes = {
    edited: propTypes.object,
    shop: propTypes.bool.isRequired
};


export default MobileMenuFooter;