/* eslint-disable no-undef */
import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Anchor } from 'shared/components/Anchor/Anchor';

const SeoMenu = ({ seoContent }) => {
    return (
        <div className='seo-menu'>
            <div className='seo-menu__menu-links'>
                {seoContent && seoContent.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            {item.viewAllLink && <Anchor url={item.viewAllLink}>{item.title}</Anchor>}
                            {item.items.map((secondLevelItem, secondLevelIndex) => {
                                return (
                                    <Fragment key={secondLevelIndex}>
                                        {
                                            !secondLevelItem.level &&
                                            <Anchor url={secondLevelItem.link}>{secondLevelItem.title}</Anchor>
                                        }
                                        {secondLevelItem.level && secondLevelItem.level.map((thirdLevelItem, thirdLevelIndex) => {
                                            return (
                                                <Fragment key={thirdLevelIndex}>
                                                    {thirdLevelItem.viewAllLink && <Anchor url={thirdLevelItem.viewAllLink}>{thirdLevelItem.title}</Anchor>}
                                                    {thirdLevelItem.items.map((subItem, subIndex) => {
                                                        return (
                                                            <Anchor key={subIndex} url={subItem.link}>{subItem.title}</Anchor>
                                                        );
                                                    })}
                                                </Fragment>
                                            );
                                        })}
                                    </Fragment>
                                );
                            })}
                        </Fragment>
                    );
                })}
            </div>
            {seoContent[0].edited &&
                <div className='seo-menu__edited-links'>
                    {seoContent[0].edited.items.map((item, index) => {
                        return (
                            <Anchor key={index} url={item.link}>{item.title}</Anchor>
                        );
                    })}
                </div>
            }
            {seoContent[0].brands &&
                <div className='seo-menu__brand-links'>
                    {_.map(seoContent[0].brands, (brandsForLetter) => {
                        const doesLetterHaveAnyBrands = brandsForLetter.length > 0;
                        return (
                            doesLetterHaveAnyBrands &&
                            brandsForLetter.map((brand) => <Anchor key={brand.id} url={brand.link} >{brand.name}</Anchor>)
                        );
                    })}
                </div>
            }
        </div>
    );
};

SeoMenu.propTypes = {
    seoContent: propTypes.array.isRequired,
    t: propTypes.func.isRequired
};

export default withTranslation('header')(SeoMenu);