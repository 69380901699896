// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ReactBreakpoints from 'react-breakpoints';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthConsumer, AuthProvider } from 'authContext';

import './i18n';
import App from './App';
import './shared/styles/base.scss';
import { enableAuth0 } from './featureToggles';

const baseUrl = '/';
const rootElement = document.getElementById('react-header-root');

/* eslint-disable no-undef */
const apiEnvironment = window && window.BbayConfig ? window.BbayConfig.apiEnvironment : 'prd';

const onRedirectCallback = (appState) => {
    if (appState && appState.target) {
        window.location.replace(`${window.location.origin}${appState.target}`);
    }
};

const breakpoints = {
    mobile: 0,
    tablet: 1024,
    desktop: 1280
};

if (rootElement != null) {
    if (enableAuth0) {
        ReactDOM.render(
            <Auth0Provider
                domain={`auth${apiEnvironment === 'prd' ? '' : '.dev'}.beautybay.com`}
                clientId={apiEnvironment === 'prd' ? 'QQsAsZRIKeYa8NRQK3oP8nNxKk91poyk' : 'JU6LoFoQTFLOCfU7wyXOISo0SAD4Pesg'}
                audience='beautybay-apis'
                redirectUri={window.location.origin}
                useCookiesForTransactions={true}
                cacheLocation='localstorage'
                useRefreshTokens={true}
                onRedirectCallback={onRedirectCallback}
            >
                <AuthProvider>
                    <AuthConsumer>
                        {
                            () => (
                                <ReactBreakpoints breakpoints={breakpoints}>
                                    <BrowserRouter basename={baseUrl}>
                                        <App />
                                    </BrowserRouter>
                                </ReactBreakpoints>)
                        }</AuthConsumer>
                </AuthProvider>
            </Auth0Provider>
            ,
            rootElement
        );
    } else {
        ReactDOM.render(
            <ReactBreakpoints breakpoints={breakpoints}>
                <BrowserRouter basename={baseUrl}>
                    <App />
                </BrowserRouter>
            </ReactBreakpoints>,
            rootElement
        );
    }
}

