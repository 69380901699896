const apis = {
    account: 'https://account-api.public.API_ENVIRONMENT.beautybay.com',
    bag: 'https://bag-api.public.API_ENVIRONMENT.beautybay.com',
    header: 'https://header-api.public.API_ENVIRONMENT.beautybay.com',
    home: 'https://home-api.public.API_ENVIRONMENT.beautybay.com',
    lister: 'https://lister-page-api.public.API_ENVIRONMENT.beautybay.com',
    skincare: 'https://lister-api.public.API_ENVIRONMENT.beautybay.com',
    notifyme: 'https://notify-me.public.API_ENVIRONMENT.beautybay.com',
    pdp: 'https://pdp-api.public.API_ENVIRONMENT.beautybay.com',
    search: 'https://search-api.public.API_ENVIRONMENT.beautybay.com',
    loyalty: 'https://loyalty-api.public.API_ENVIRONMENT.beautybay.com',
    checkout: 'https://checkout-page-api.public.API_ENVIRONMENT.beautybay.com',
    help: 'https://customer-service-api.public.API_ENVIRONMENT.beautybay.com',
    careers: 'https://careers.public.API_ENVIRONMENT.beautybay.com',
    edited: 'https://edited-api.public.API_ENVIRONMENT.beautybay.com',
    wishlists: 'https://wishlists.public.API_ENVIRONMENT.beautybay.com'
};

export const getUrl = (api) => {
    const apiEnvironment = window && window.BbayConfig ? window.BbayConfig.apiEnvironment : 'prd';
    //const apiEnvironment = 'prd'//window && window.BbayConfig ? window.BbayConfig.apiEnvironment : 'prd';
   
    return apis[api.toLowerCase()].replace('API_ENVIRONMENT', apiEnvironment);
};