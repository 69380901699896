import qs from 'qs';

import { isPreview } from './lib/preview';
import { getUrl } from './lib/apiUrls';
import { get } from './lib/fetch';

import HeaderInformationData from '../fixtures/headerInformationData.json';

const apiUrl = getUrl('header');

export const getHeaderContent = () => {
    const queryParams = isPreview(window.location.search) ? { preview: true } : {};
    const queryString = qs.stringify(queryParams, { addQueryPrefix: true });

    return get(`${apiUrl}/header${queryString}`)
        .then(res => res.json());
};

export const getHeaderMenu = () => {
    const queryParams = isPreview(window.location.search) ? { preview: true } : {};
    const queryString = qs.stringify(queryParams, { addQueryPrefix: true });

    return get(`${apiUrl}/v2/header/menu${queryString}`)
        .then(res => res.json());
};

export const getHeaderUserData = () => {
    const queryParams = isPreview(window.location.search) ? { preview: true } : {};
    const queryString = qs.stringify(queryParams, { addQueryPrefix: true });

    return get(`${apiUrl}/header/user${queryString}`)
        .then(res => res.json());
};

export const getHeaderLinks = () => {
    const queryParams = isPreview(window.location.search) ? { preview: true } : {};
    const queryString = qs.stringify(queryParams, { addQueryPrefix: true });

    return get(`${apiUrl}/header/links${queryString}`)
        .then(res => res.json());
};

export const getUsp = () => {
    const queryParams = isPreview(window.location.search) ? { preview: true } : {};
    const queryString = qs.stringify(queryParams, { addQueryPrefix: true });

    return get(`${apiUrl}/usp${queryString}`)
        .then(res => res.json());
};

export const getHeaderInformationData = () => {
    return Promise.resolve(HeaderInformationData);
};