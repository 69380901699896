import { post, del, get, put } from './lib/fetch';
import { getUrl } from './lib/apiUrls';

const wishlistsUrl = getUrl('wishlists');

const addToWishlist = (sku) => {
    return post(`${wishlistsUrl}/wishlist/${sku}/`)
        .then(res => ({ success: res.ok, status: res.status }));
};

const removeFromWishlist = (sku) => {
    return del(`${wishlistsUrl}/wishlist/${sku}/`)
        .then(res => ({ success: res.ok, status: res.status }));
};

const updateTags = (sku, wishlistIds) => {
    return post(`${wishlistsUrl}/wishlist/tag/${sku}/`, { wishlistIds })
        .then(res => ({ success: res.ok, status: res.status }));
};

const addNewTag = tagName => {
    return post(`${wishlistsUrl}/wishlist/`, { name: tagName })
        .then(response => response.json());
};

const deleteTag = id => {
    return del(`${wishlistsUrl}/wishlist/tag/${id}`)
        .then(res => ({ success: res.ok, status: res.status }));
};

const updateTagName = (id, tagName) => {
    return put(`${wishlistsUrl}/wishlist/tag/${id}/`, { name: tagName })
        .then(res => ({ success: res.ok, status: res.status }));
};

const getWishlist = (page, id) => {
    const endpoint = id ? `${wishlistsUrl}/wishlist/${id}/?page=${page}` : `${wishlistsUrl}/wishlist/?page=${page}`;
    
    return get(endpoint)
        .then(res => res.json());
};

const getShared = (page, id) => {
    const endpoint = id ? `${wishlistsUrl}/wishlist/shared/${id}/?page=${page}` : `${wishlistsUrl}/wishlist/?page=${page}`;
    
    return get(endpoint)
        .then(res => res.json());
};

export default {
    addToWishlist,
    removeFromWishlist,
    getWishlist,
    updateTags,
    addNewTag,
    updateTagName,
    deleteTag,
    getShared
};