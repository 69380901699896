import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import RouteResolver from './RouteResolver';

const LocaleRoute = ({ path, ...props }) => {
    const resolvedRoute = RouteResolver.resolve(path);
    return <Route {...props} path={resolvedRoute} />;
};

LocaleRoute.propTypes = {
    path: PropTypes.string.isRequired,
};

export default LocaleRoute;