import React from 'react';
import propTypes from 'prop-types';

import SeoMenu from '../mobile/SeoMenu';


import { Anchor } from 'shared/components/Anchor/Anchor';

import '../../styles/desktop/DesktopHeaderMenu.scss';

const DesktopHeaderMenu = ({ menuContent, toggleMenu, activeItem, hoverSwitchMenu }) => {

    const getProps = (item) => {
        let props = {
            key: item.title,
            onClick: () => toggleMenu(item.level[0], item.title),
            style: item.highlightColour && { color: `${item.highlightColour}` }
        };

        if (activeItem && activeItem !== item.title) {
            props = {
                ...props,
                onMouseEnter: () => hoverSwitchMenu(item.level[0], item.title),
                onMouseLeave: () => { }
            };
        }

        return props;
    };

    const getAccent = (item) => {
        let props;
        
        if (activeItem === item.title) {
            props = {
                style: { backgroundColor: item.level[0].accentColour }
            };
        }

        return props;
    };

    return (
        <nav className='desktop-header__menu'>
            <ul className='desktop-header__menu--links'>
                {menuContent &&
                    menuContent.shop.featuredShopItems.map((item, index) => {
                        const featuredItemProps = getProps(item);
                        const featuredAccentColour = getAccent(item);
                        const trackingDataFeaturedLink = { event: 'top-menu-nav', category: item.title, action: item.title, label: item.title };

                        return (
                            <li className='desktop-header__menu--link' key={index}>
                                <Anchor trackingData={trackingDataFeaturedLink} className={`feature-shop-item ${item.title === activeItem ? 'active' : ''}`} {...featuredItemProps}>{item.title}</Anchor>
                                <span className='desktop-header__menu--link-accent' {...featuredAccentColour}/>
                                <SeoMenu seoContent={item.level}/>
                            </li>
                        );
                    })}
                {menuContent &&
                    menuContent.shop.shopItems.map((item, index) => {
                        const shopItemProps = getProps(item);
                        const shopAccentColour = getAccent(item);
                        const trackingDataLink = { event: 'top-menu-nav', category: item.title, action: item.title, label: item.title };

                        return (
                            <li className='desktop-header__menu--link'  key={index}>
                                <Anchor trackingData={trackingDataLink} className={`shop-item ${item.title === activeItem ? 'active' : ''}`} {...shopItemProps}>{item.title}</Anchor>
                                <span className='desktop-header__menu--link-accent' {...shopAccentColour}/>
                                <SeoMenu seoContent={item.level}/>
                            </li>
                        );
                    })}
            </ul>
        </nav>
    );
};

DesktopHeaderMenu.propTypes = {
    menuContent: propTypes.object.isRequired,
    toggleMenu: propTypes.func.isRequired,
    activeItem: propTypes.string,
    hoverSwitchMenu: propTypes.func.isRequired,
};

export default DesktopHeaderMenu;