/* eslint-disable no-undef */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './languages/en.json';
import fr from './languages/fr.json';
import de from './languages/de.json';
import es from './languages/es.json';
import ar from './languages/ar.json';
import enUS from './languages/en-US.json';

import bbLanguageDetector from './languages/languageDetector';
const languageDetector = new LanguageDetector();
languageDetector.addDetector(bbLanguageDetector);

const options = {
    // order and from where user language should be detected
    order: ['bbLanguageDetector']
};

i18n
    .use(languageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: options,
        resources: { en, fr, de, 'en-US': enUS, es, ar },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false 
        }
    });



export default i18n;