import React from 'react';
import propTypes from 'prop-types';
import { ReactComponent as InfoIcon } from 'shared/svg/info-filled.svg';
import { ReactComponent as ThumbUpIcon } from 'shared/svg/thumb-up-filled.svg';
import { ReactComponent as WarningIcon } from 'shared/svg/white/warning-filled.svg';

import './Messages.scss';

export const SuccessMsg = ({ message }) => {
    return (
        <span className="message success"><ThumbUpIcon /><p className="message-text">{message}</p></span>
    );
};

export const InfoMsg = ({ message }) => {
    return (
        <span className="message info"><InfoIcon /><p className="message-text">{message}</p></span>
    );
};

export const WarningMsg = ({ message }) => {
    return (
        <span className="message warning"><WarningIcon /><p className="message-text">{message}</p></span>
    );
};

SuccessMsg.propTypes = {
    message: propTypes.string.isRequired,
};

InfoMsg.propTypes = {
    message: propTypes.string.isRequired,
};

WarningMsg.propTypes = {
    message: propTypes.string.isRequired,
};