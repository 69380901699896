import React from 'react';
import propTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';


import { Anchor } from 'shared/components/Anchor/Anchor';
import SocialSection from '../../components/shared/SocialSection';
import { HeaderConsumer } from 'headerContext';

import '../../styles/desktop/ConnectWithUsContent.scss';

const ConnectWithUsContent = ({ socialContent }) => {
    return (
        <HeaderConsumer>
            {
                ({ signUpToNewsletter, isMena }) => (
                    <div className='connect-with-us' >
                        <div className='connect-with-us__section'>
                            {
                                socialContent.text &&
                                <div className='connect-with-us__section--copy'>
                                    <Markdown>{socialContent.text}</Markdown>
                                </div>
                            }
                        </div>
                        {!isMena && <div className='connect-with-us__section'>
                            {socialContent && socialContent.posters && socialContent.posters.map((poster, index) => {
                                const socialPosterTrackingData = { event: 'connectwithus', eventCategory: 'Connect With Us', action: 'Connect With Us Poster Click', label: poster.subHeading, eventId: '2584' };
                                return (
                                    <div key={index} className='connect-with-us__section--poster'>
                                        <Anchor trackingData={socialPosterTrackingData} url={poster.link} target='_blank'>
                                            {
                                                poster.imageUrl &&
                                                <div className='connect-with-us__section--poster-image' style={{ backgroundImage: `url(${poster.imageUrl})` }} />
                                            }
                                            {
                                                poster.heading &&
                                                <h4>{poster.heading}</h4>
                                            }
                                            {
                                                poster.subHeading &&
                                                <p>{poster.subHeading}</p>
                                            }
                                        </Anchor>
                                    </div>
                                );
                            })}
                        </div>}
                        <div className='connect-with-us__section'>
                            <SocialSection signUpToNewsletter={signUpToNewsletter} isMena={isMena} />
                        </div>
                    </div>
                )
            }
        </HeaderConsumer>
    );
};

ConnectWithUsContent.propTypes = {
    socialContent: propTypes.object.isRequired
};

export default ConnectWithUsContent;