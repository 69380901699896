
import React from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ReactComponent as SearchIcon } from 'shared/svg/search.svg';
import { ReactComponent as CloseIcon } from 'shared/svg/close.svg';

import './styles/searchInput.scss';

export const SearchInput = ({ searchTerm, onChange, submitSearch, t, closeMenu, clearSearch, inputRef, mobile }) => {


    return (
        <div className='search-input__container'>
            {mobile &&
                <div className='search-input__close' onClick={closeMenu}>
                    <CloseIcon className='icon close-icon' />
                </div>
            }
            <div className='search-input__input-container'>
                <form onSubmit={submitSearch || ((e) => { e.preventDefault(); })} className='search-input__form'>
                    <input
                        onClick={e => e.stopPropagation()}
                        type='text'
                        className='search-input__input'
                        placeholder={t('header:searchingPlaceholder')}
                        id='search-input'
                        value={searchTerm}
                        onChange={onChange}
                        ref={inputRef}
                    />
                    {/* required for attraqt autocomplete dropdown*/}
                    <div id='autocomplete-dropdown'></div>
                </form>
                {searchTerm &&
                    <div className='search-input__controls'>
                        <div className='search-input__controls--clear' onClick={clearSearch}>
                            <div className='search-input__controls--clear-inner'>
                                <CloseIcon className='icon clear-icon' />
                            </div>
                        </div>
                        <div className='search-input__controls--submit' onClick={submitSearch}>
                            <SearchIcon className='icon submit-icon' />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

SearchInput.propTypes = {
    submitSearch: propTypes.func,
    onFocus: propTypes.func,
    mobile: propTypes.bool,
    onBlur: propTypes.func,
    onChange: propTypes.func,
    searchTerm: propTypes.string,
    t: propTypes.func.isRequired,
    closeMenu: propTypes.func.isRequired,
    clearSearch: propTypes.func.isRequired,
    inputRef: propTypes.func,
};

export default withTranslation('header')(SearchInput);