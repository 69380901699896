/* eslint-disable no-undef */
import React from 'react';
import propTypes from 'prop-types';
import BrandListings from './BrandListings';
import BrandAlphabet from './BrandAlphabet';

const BrandMenu = ({ closeMenu, brands, scrollToLetter, activeLetter }) => {
    return (
        <div>
            <BrandAlphabet brands={brands} scrollToLetter={scrollToLetter} />
            <BrandListings brands={brands} closeMenu={closeMenu} activeLetter={activeLetter} />
        </div>
    );
};

BrandMenu.propTypes = {
    closeMenu: propTypes.func.isRequired,
    brands: propTypes.object.isRequired,
    scrollToLetter: propTypes.func.isRequired,
    activeLetter: propTypes.string
};

export default BrandMenu;