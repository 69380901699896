import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

import { Anchor } from 'shared/components/Anchor/Anchor';
import { ReactComponent as SearchIcon } from 'shared/svg/searchbox.svg';
import './styles/searchSuggestions.scss';

const SearchSuggestions = ({ terms, searchTerm, showResults, closeMenu }) => (
    <Fragment>
        {terms && terms.length && showResults && searchTerm !== '' ?
            <div className='search-suggestions'>
                {
                    terms.map((term, index) => {
                        return (
                            <Anchor key={index} url={`/l/?q=${term.term}`} onClick={() => closeMenu()}>
                                <SearchIcon />
                                <Highlighter
                                    key={index}
                                    highlightClassName='search-suggestions__matched-text'
                                    unhighlightClassName='search-suggestions__unmatched-text'
                                    searchWords={[`${searchTerm}`]}
                                    autoEscape={true}
                                    textToHighlight={`${term.term}`}
                                />
                            </Anchor>
                        );
                    })
                }
            </div>
            : null}
    </ Fragment>
);

SearchSuggestions.propTypes = {
    showResults: propTypes.bool.isRequired,
    terms: propTypes.array,
    searchTerm: propTypes.string,
    closeMenu: propTypes.func
};

export default SearchSuggestions;