import React from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Anchor } from 'shared/components/Anchor/Anchor';
import { ReactComponent as SearchIcon } from 'shared/svg/search.svg';

import '../../styles/shared/SearchBar.scss';

const searchTrackingData = { event: 'top-menu-search', category: 'Top Menu Search', action: 'Top Menu Item Click', label: 'Search' };

const SearchBar = ({ t, toggleSearch, secondLevel, userInfo }) => {
    return (
        <Anchor trackingData={searchTrackingData} onClick={toggleSearch}>
            <div className='header-search-container'>
                {
                    secondLevel ?
                        <p>{t('header:level2SearchPlaceholder')}</p> :
                        <p>{userInfo && userInfo.firstName ? t('header:searchPlaceholderLoggedIn', {firstName: userInfo.firstName}) : t('header:searchPlaceholder')}</p>
                }
                <SearchIcon />
            </div>
        </Anchor>
    );
};

SearchBar.propTypes = {
    toggleSearch: propTypes.func.isRequired,
    t: propTypes.func.isRequired,
    secondLevel: propTypes.bool.isRequired,
    userInfo: propTypes.object,
};

export default withTranslation(['header'])(SearchBar);