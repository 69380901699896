import { getUrl } from './lib/apiUrls';
import { post, get } from './lib/fetch';

const apiUrl = getUrl('account');

export const getSummaryContent = () => {
    return get(`${apiUrl}/summary`)
        .then(res => res.json());
};

export const newsletterSignUp = (email) => {
    return post(`${apiUrl}/marketing?email=${email}`)
        .then(response => response.json());
};


export const getMarketingPreferences = () => {
    return Promise.resolve({
        'newsletter': true,
        'tribe': true,
        'all': true
    });
};

export const setMarketingPreferences = () => {


};

export const forgotPassword = () => {

};

export const validateToken = () => {
    return Promise.resolve({
        isValid : true
    });
};



