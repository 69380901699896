import React, { useRef, Fragment, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'shared/svg/close.svg';
import { ReactComponent as ScrollToTop } from 'shared/svg/back-to-top.svg';
import { Anchor } from 'shared/components/Anchor/Anchor';
import BrandAlphabet from '../shared/BrandAlphabet';
import BrandListings from '../shared/BrandListings';

const DesktopMenuContentBrands = ({ menuContent, closeMenu, menuTransition, setMenuTransition, headerMenuRef, headerRef }) => {

    const container = useRef(null);

    const [brandScroll, setBrandScroll] = useState(null);
    const [brandAlphabetFixed, setBrandAlphabetFixed] = useState(false);
    const [activeLetter, setActiveLetter] = useState(null);

    useEffect(() => {
        if (brandScroll) {
            headerMenuRef.current.scrollTo({ behavior: 'smooth', 'top': brandScroll.offsetTop + 187 });
            setBrandScroll(null);
        }
    }, [brandScroll]);

    useEffect(() => {
        if (headerMenuRef.current.scrollTop > headerRef.current.clientHeight) {
            setBrandAlphabetFixed(true);
        } else {
            setBrandAlphabetFixed(false);
        }
    });

    useEffect(() => {
        setMenuTransition(true);
    }, [menuContent]);


    const scrollToLetter = (letter) => {
        if (!container) {
            return;
        }
        const elementToScrollTo = document.getElementsByClassName(`brand-section-header-${letter}`)[0];
        setActiveLetter(letter);
        setBrandScroll(elementToScrollTo);
    };

    const scrollToTop = () => {
        headerMenuRef.current.scrollTo({ behavior: 'smooth', 'top': 0 });
    };
    useWindowScroll(headerMenuRef);

    return (
        <Fragment>
            <div className={`desktop-menu-content__header ${menuTransition ? 'active' : ''} ${brandAlphabetFixed ? 'fixed' : ''}`}>
                <div className='scroll-to-top-icon__container'><ScrollToTop className='icon scroll-to-top-icon' onClick={() => scrollToTop()} /></div>
                <BrandAlphabet brands={menuContent.brands} scrollToLetter={scrollToLetter} />
                <div className='close-icon__container'><CloseIcon className='icon close-icon' onClick={() => closeMenu()} /></div>
            </div>
            <div className={`desktop-menu-content__container  ${menuTransition ? 'active' : ''}`}>
                <div ref={container} className='desktop-menu-content__column brand-search-column'>
                    <BrandListings brands={menuContent.brands} closeMenu={closeMenu} activeLetter={activeLetter} />
                </div>
                <div className='desktop-menu-content__column brand-link-column'>
                    {
                        menuContent.items.map((section, sectionIndex) => {
                            const headerTrackingData = { event: 'top-menu-nav', eventCategory: section.title, action: section.title, label: section.title };

                            return (

                                <div key={sectionIndex} className='desktop-menu-content__section'>
                                    <Anchor
                                        className='desktop-menu-content__section--title'
                                        url={section.link}
                                        key={sectionIndex}
                                        onClick={() => closeMenu()}
                                        trackingData={headerTrackingData}
                                    >
                                        {section.title}
                                    </Anchor>
                                    {section.level &&
                                        section.level[0].items.map((item, itemIndex) => {
                                            const sectionItemTrackingData = { event: 'top-menu-nav', eventCategory: section.title, action: section.title, label: item.title };

                                            return (
                                                <Anchor
                                                    className='desktop-menu-content__section--link'
                                                    url={item.link}
                                                    key={itemIndex}
                                                    onClick={() => closeMenu()}
                                                    trackingData={sectionItemTrackingData}
                                                >
                                                    {item.title}
                                                </Anchor>
                                            );
                                        })}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </Fragment>
    );
};

const useWindowScroll = (headerMenuRef) => {
    const [scrollDepth, setScrollDepth] = useState(0);
    const updateScrollDepth = () => {
        setScrollDepth(headerMenuRef.current.scrollTop);
    };
    useEffect(() => {
        headerMenuRef.current.addEventListener('scroll', updateScrollDepth);
    });
    return scrollDepth;
};

DesktopMenuContentBrands.propTypes = {
    menuContent: propTypes.object.isRequired,
    headerRef: propTypes.object.isRequired,
    headerMenuRef: propTypes.object.isRequired,
    closeMenu: propTypes.func.isRequired,
    setMenuTransition: propTypes.func.isRequired,
    menuTransition: propTypes.bool.isRequired
};

export default DesktopMenuContentBrands;