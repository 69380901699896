/* eslint-disable no-undef */
import React, { Fragment, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Anchor } from 'shared/components/Anchor/Anchor';

import { ReactComponent as EditedLogo } from 'shared/svg/edited-logo.svg';
import { ReactComponent as TribeLogo } from 'shared/svg/logo-tribe-full.svg';
import { ReactComponent as TribeFlame } from 'shared/svg/flame-yellow.svg';

import '../../styles/mobile/InformationSection.scss';
import SeoMenu from './SeoMenu';

const InformationSection = ({ t, getCountries, userData, toggleInformationAndOther, menuContent, location }) => {
    const [country, setCountry] = useState({});
    const [userHeaderData, setUserHeaderData] = useState({});

    useEffect(() => {
        setUserHeaderData(userData);
    }, [userData]);

    useEffect(() => {
        if(userHeaderData.countryCode) {
            getCountries()
                .then(countries => {
                    let userCountry = countries.find(c => c.countryCode === userHeaderData.countryCode);
                    if (!userCountry) {
                        userCountry = countries.find(c => c.countryCode === 'GB');
                    }
                    setCountry(userCountry);
                });
        }
    }, [userHeaderData]);

    const showInfo = () => {
        toggleInformationAndOther(menuContent.info, false);
    };

    const trackingDataEdited = { event: 'mobileMenuLink', category: 'Mobile Menu - Information Section', action: 'Mobile Menu Click', label: 'Edited' };
    const trackingDataTribe = { event: 'mobileMenuLink', category: 'Mobile Menu - Information Section', action: 'Mobile Menu Click', label: 'Tribe' };
    const trackingDataOrders = { event: 'mobileMenuLink', category: 'Mobile Menu - Information Section', action: 'Mobile Menu Click', label: 'Orders' };
    const trackingDataInfoAndOther = { event: 'mobileMenuLink', category: 'Mobile Menu - Information Section', action: 'Mobile Menu Click', label: 'Info & Other' };
    const currencySymbol = country.currencySymbol ? `(${country.currencySymbol})` : '';

    return (
        <div className='information-section'>
            <Anchor trackingData={trackingDataEdited} className='information-section__link' url='/edited'>
                <span className='main-section'>
                    <EditedLogo className='edited-logo' />
                </span>
            </Anchor>
            <Anchor trackingData={trackingDataTribe} className='information-section__link' url='/account/tribe/overview'>
                <span className='main-section'>
                    <TribeLogo className='tribe-logo' />
                </span>
                <span className='sub-section'>
                    {userHeaderData.loggedIn && userHeaderData.tribe.optedIn &&
                        <span className='subicon'>
                            <TribeFlame className='tribe-flame' />
                        </span>
                    }
                    <span className='subheading'>
                        {userHeaderData.loggedIn && userHeaderData.tribe.optedIn ?
                            <Fragment>
                                {`${userHeaderData.tribe.points} ${t('header:informationSection.points')}`}
                            </Fragment> :
                            t('header:informationSection.getExclusivePerks')
                        }
                    </span>
                </span>
            </Anchor>
            <Anchor trackingData={trackingDataOrders} className='information-section__link' url='/orders/recent'>
                <span className='main-section'>
                    {t('header:informationSection.myHauls')}
                </span>
            </Anchor>
            <Anchor trackingData={trackingDataInfoAndOther} className='information-section__link' onClick={showInfo}>
                <span className='main-section'>
                    {t('header:informationSection.informationAndOther')}
                </span>
                {
                    menuContent && menuContent.info &&
                    <SeoMenu seoContent={menuContent.info} />
                }
            </Anchor>
            <Anchor className='information-section__link' url={`/country-selection/?returnUrl=${location.pathname}`} trackingData={{event: 'SiteFlagClick'}}>
                <span className='main-section'>
                    {t('header:informationSection.sitePreferences')}
                </span>
                <span className='sub-section'>
                    <span className='subheading'>{`${country.currency} ${currencySymbol} ${userHeaderData.language ? userHeaderData.language.toUpperCase() : ''}`}</span>
                    {country.flag && <span className='subicon flag'><img src={country.flag.imageUrl} alt={`${country.countryCode} - Flag`} /></span>}
                </span>
            </Anchor>
        </div>
    );
};

InformationSection.propTypes = {
    t: propTypes.func.isRequired,
    toggleInformationAndOther: propTypes.func.isRequired,
    userData: propTypes.object.isRequired,
    getCountries: propTypes.func.isRequired,
    menuContent: propTypes.object.isRequired,
    location: propTypes.object
};

export default withTranslation('header')(InformationSection);