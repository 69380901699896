const cultureLookup = {
    en: 'en-GB',
    fr: 'fr-FR'
};

class User {
    constructor() {
        this.loggedIn = false;
        this.email = null;
        this.locale = 'en-GB';
        this.countryCode = 'GB';
        this.language = 'en';
        this.culture = 'en-GB';
        this.guestId = '';
        this.tribe = { optedIn: false, points: 0 };
        this.userSummary = { type: 'new', orders: 0 };
        this.userInfo = { firstName: null, lastName: null };
    }

    withToken(token) {
        this.loggedIn = token.aud.includes('web_user');
        this.email = token.email ? token.email : null;
        this.locale = token.locale;
        this.countryCode = token.locale.split('-')[1];
        this.language = token.locale.split('-')[0];
        this.culture = cultureLookup[this.language] || 'en-GB';
        this.guestId = token.sub;
        return this;
    }

    withAuth0UserInfo(user) {
        this.loggedIn = true;
        this.email = user.email;
        this.locale = window.localStorage.getItem('locale') || 'en-GB';
        this.countryCode = this.locale.split('-')[1];
        this.language = this.locale.split('-')[0];
        this.culture = cultureLookup[this.language] || 'en-GB';
        this.guestId = window.localStorage.getItem('bagId');
        return this;
    }
    
    withAuth0GuestUserInfo() {
        this.locale = window.localStorage.getItem('locale') || 'en-GB';
        this.countryCode = this.locale.split('-')[1];
        this.language = this.locale.split('-')[0];
        this.culture = cultureLookup[this.language] || 'en-GB';
        this.guestId = window.localStorage.getItem('bagId');
        return this;
    }

    withTribe(tribeDetails) {
        this.tribe = {
            optedIn: tribeDetails.optedIn,
            points: tribeDetails.optedIn ? tribeDetails.points : 0,
            level: tribeDetails.optedIn ? tribeDetails.tier.level : null
        };
        return this;
    }

    withUserOrderSummary(summaryDetails) {
        if (summaryDetails.orderCount > 0) {
            this.userSummary = {
                type: 'returning',
                orders: summaryDetails.orderCount,
                daysSinceLastOrder: summaryDetails.daysSinceLastOrder
            };
            return this;
        }

        return this;
    }

    withCustomerInfo(customerInfo) {
        this.userInfo = {
            firstName: customerInfo.firstname,
            lastName: customerInfo.surname
        };
        return this;
    }
}

export default User;