import React from 'react';
import PropTypes from 'prop-types';

export const Input = ({ type, placeholder, value, onChange, onBlur }) => {
    return (
        <input type={type} placeholder={placeholder} value={value} onChange={onChange} onBlur={onBlur} />
    );
};

Input.propTypes = {
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
};
