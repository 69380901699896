import React from 'react';
import propTypes from 'prop-types';

import Logo from '../shared/Logo';
import SearchBar from '../shared/SearchBar';
import DesktopHeaderIcons from './DesktopHeaderIcons';

import '../../styles/desktop/DesktopHeaderMain.scss';

const DesktopHeaderMain = ({ toggleSearch, user, bag, wishlist, isOnBagPage }) => {
    return (
        <div className='desktop-header__main'>
            <div className='container'>
                <div className='desktop-header__main--logo'>
                    <Logo />
                </div>
                <div className='desktop-header__main--search'>
                    <SearchBar toggleSearch={toggleSearch} secondLevel={false} userInfo={user.userInfo}/>
                </div>
                <div className='desktop-header__main--icons'>
                    <DesktopHeaderIcons user={user} bag={bag} wishlist={wishlist} isOnBagPage={isOnBagPage} />
                </div>
            </div>
        </div>
    );
};

DesktopHeaderMain.propTypes = {
    toggleSearch: propTypes.func.isRequired,
    user: propTypes.object.isRequired,
    bag: propTypes.object.isRequired,
    wishlist: propTypes.object.isRequired,
    isOnBagPage: propTypes.bool.isRequired
};

export default DesktopHeaderMain;