import qs from 'qs';

import { isPreview } from './lib/preview';
import { getUrl } from './lib/apiUrls';
import { get } from './lib/fetch';

const apiUrl = getUrl('search');

export const getSearchLinks = () => {
    const queryParams = isPreview(window.location.search) ? { preview: true } : {};
    const queryString = qs.stringify(queryParams, { addQueryPrefix: true });
    
    return get(`${apiUrl}/search/links${queryString}`)
        .then(res => res.json());
};

export const getAutocomplete = (searchTerm) => {
    const query = qs.stringify({searchTerm});
    return get(`${apiUrl}/search/autocomplete?${query}`)
        .then(res => res.json());
};