import { post, get, del, put } from './lib/fetch';
import { getUrl } from './lib/apiUrls';

const apiUrl = getUrl('account');
const helpAPiUrl = getUrl('help');

const login = (email, password, guestId, token) => {
    return post('apis/account/v2', { email, password, guestId, token })
        .then(response => response.json());
};

const logout = () => {
    return post('apis/account/logout')
        .then(response => response.json());
};

const getSummaryContent = () => {
    return get(`${apiUrl}/summary`)
        .then(res => {
            if (res.status === 404) {
                return null;
            }
            return res.json();
        });
};

const getCountries = () => {
    return get(`${apiUrl}/countries`)
        .then(response => response.json());
};

const getOrder = (orderId) => {
    return get(`${apiUrl}/orders/${orderId}`)
        .then(response => response.json());
};

const getOrders = (from, size) => {
    return get(`${apiUrl}/orders/?from=${from}&size=${size}`)
        .then(response => response.json());
};

const getAddresses = () => {
    return get(`${apiUrl}/addresses`)
        .then(response => response.json());
};

const getAddress = (addressId) => {
    return get(`${apiUrl}/addresses/${addressId}`)
        .then(response => response.json());
};

const editAddress = (addressId, address) => {
    return put(`${apiUrl}/addresses/${addressId}`, { address })
        .then(res => ({ success: res.ok, status: res.status }));
};

const deleteAddress = (addressId) => {
    return del(`${apiUrl}/addresses/${addressId}`);
};

const createAddress = (address) => {
    return post(`${apiUrl}/addresses`, { address })
        .then(res => ({ success: res.ok, status: res.status }));
};

const setPreferences = (preferences) => {
    return post(`${apiUrl}/preferences`, { preferences })
        .then(res => ({ success: res.ok, status: res.status }));
};

const getPreferences = () => {
    return get(`${apiUrl}/preferences`)
        .then(response => response.json());
};

const forgotPassword = (email) => {
    return post(`api/account/forgotpassword?email=${email}`)
        .then(response => response.json());
};

const validateRecaptcha = (token) => {
    return post(`/apis/account/recaptcha`, { token })
        .then(() => { })
        .catch(() => { });
};

const updateLocale = (locale) => {
    return post(`/apis/account/locale/${locale}`)
        .then(res => res.json());
};

const getGiftcards = () => {
    return get('/api/giftcards/get')
        .then(response => response.json());
};

const registerGiftCard = (panNumber, pinNumber) => {
    return post('/api/giftcards/register', { panNumber, pinNumber })
        .then(response => response.json());
};

const getLoyaltyData = () => {
    return get(`${apiUrl}/loyalty/details/`)
        .then(response => response.json());
};

const getLoyaltyHistory = () => {
    return get(`${apiUrl}/loyalty/history/`)
        .then(response => response.json());
};

const submitLoyaltyCode = (code) => {
    return post(`${apiUrl}/loyalty/code/${code}`)
        .then(res => res.json());
};

const purchaseReward = (rewardId) => {
    return post(`${apiUrl}/reward/${rewardId}`)
        .then(res => res.json());
};

const optCustomerIn = () => {
    return post(`${apiUrl}/loyalty/optIn`)
        .then(res => res.json());
};

const createUser = (userData) => {
    return post(`${apiUrl}/user`, userData)
        .then(res => res.json());
};

const editDetails = (userData) => {
    return put(`${apiUrl}/user`, userData)
        .then(res => res.json());
};

const getCustomerSummary = () => {
    return get(`${apiUrl}/history`)
        .then(res => res.json());
};

const getSavedCards = () => {
    return get(`${apiUrl}/savedcards`)
        .then(res => res.json());
};

const deleteSavedCard = ({ token }) => {
    return del(`${apiUrl}/savedcards/${token}`)
        .then(res => {
            return { deleted: res.ok };
        });
};

const getChatbotStatus = () => {
    return get(`${helpAPiUrl}/chatbot`)
        .then(res => res.json());
};

export default {
    login,
    logout,
    forgotPassword,
    validateRecaptcha,
    updateLocale,
    getSummaryContent,
    getOrder,
    getOrders,
    getAddresses,
    getAddress,
    deleteAddress,
    createAddress,
    editAddress,
    getCountries,
    getPreferences,
    setPreferences,
    registerGiftCard,
    getGiftcards,
    getLoyaltyData,
    getLoyaltyHistory,
    submitLoyaltyCode,
    purchaseReward,
    optCustomerIn,
    createUser,
    editDetails,
    getCustomerSummary,
    getSavedCards,
    deleteSavedCard,
    getChatbotStatus
};