import React, { useEffect } from 'react';
import propTypes from 'prop-types';

import Logo from '../shared/Logo';
import InformationAndOtherContent from './InformationAndOtherContent';
import ConnectWithUsContent from './ConnectWithUsContent';
import { ReactComponent as CloseIcon } from 'shared/svg/close.svg';

import '../../styles/desktop/InformationMenuContent.scss';

const InformationMenuContent = ({ selectedOption, menuContent, closeMenu, infoTransition, setInfoTransition }) => {

    useEffect(() => {
        setInfoTransition(true);
    }, [selectedOption]);

    return (
        <div className='desktop-header__info-bar--information-menu' onClick={() => closeMenu('')}>
            <div className='information-menu__container'>
                <div className='information-menu__container--content'>
                    <div className={`information-menu ${infoTransition ? 'active' : ''}`} onClick={(e) => e.stopPropagation()}>
                        <div className='information-menu__logo'>
                            <Logo />
                        </div>
                        <div className='information-menu__content'>
                            {selectedOption === 'infoAndOther' && <InformationAndOtherContent menuContent={menuContent.info} />}
                            {selectedOption === 'connectWithUs' && <ConnectWithUsContent socialContent={menuContent.social} />}
                        </div>
                    </div>
                </div>
                <div className='information-menu__close' onClick={() => closeMenu('')}>
                    <CloseIcon className='icon close-icon' />
                </div>
            </div>
        </div>
    );
};

InformationMenuContent.propTypes = {
    selectedOption: propTypes.string.isRequired,
    menuContent: propTypes.object,
    closeMenu: propTypes.func.isRequired,
    setInfoTransition: propTypes.func.isRequired,
    infoTransition: propTypes.bool.isRequired
};

export default InformationMenuContent;