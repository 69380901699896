import { getUrl } from './lib/apiUrls';
import { get } from './lib/fetch';

const apiUrl = getUrl('account');

const getLoyaltyData = () => {
    return get(`${apiUrl}/loyalty/summary`)
        .then(res => res.json());
};

export default {
    getLoyaltyData
};