import React from 'react';
import propTypes from 'prop-types';
import map from 'lodash/map';
import BrandListing from './BrandListing';

import '../../styles/shared/BrandListings.scss';

const BrandListings = ({ brands, closeMenu, activeLetter }) => {
    return map(brands, (brandsForLetter, letter) => {
        const doesLetterHaveAnyBrands = brandsForLetter.length > 0;
        
        return doesLetterHaveAnyBrands && <BrandListing key={letter} brands={brandsForLetter} letter={letter} closeMenu={closeMenu} selected={activeLetter === letter} />;
    });
};

BrandListings.propTypes = {
    brands: propTypes.object.isRequired,
    closeMenu: propTypes.func.isRequired
};

export default BrandListings;