/* eslint-disable no-unused-vars */

import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import { enableAuth0 } from '../featureToggles';


export default {
    name: 'bbLanguageDetector',

    lookup(options) {
        const cookie = Cookies.get('AuthToken');
        let locale = 'en';
        if (enableAuth0) {
            const localeCookie = Cookies.get('Locale') || 'en-GB';
            if (localeCookie !== 'en-US' && localeCookie !== 'en-AU') {
                locale = localeCookie.split('-')[0].toLowerCase();
            }
            else {
                locale = localeCookie;
            }
        } else if (cookie) {
            const user = jwt.decode(cookie);
            if (user.locale !== 'en-US' && user.locale !== 'en-AU') {
                locale = user.locale.split('-')[0].toLowerCase();
            }
            else {
                locale = user.locale;
            }
        }
        return locale;
    },

    cacheUserLanguage(lng, options) {

        // options -> are passed in options
        // lng -> current language, will be called after init and on changeLanguage

        // store it
    }
};