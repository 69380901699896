import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { Anchor } from 'shared/components/Anchor/Anchor';

import '../../styles/shared/Usp.scss';

class Usp extends Component {
    constructor(props) {
        super(props);
        this.fadeTimeout = 500;
        this.state = {
            messages: [],
            fixedMessage: '',
            selectedMessageIndex: 0,
            show: false,
            showFixedMessage: false
        };
    }

    componentDidMount() {
        this.props.headerApi.getUsp()
            .then(response => {
                if (response.messages.length) {
                    this.props.setUspVisible();
                }
                this.setState({
                    messages: response.messages,
                    fixedMessage : response.fixedMessage.message,
                    showFixedMessage : response.fixedMessage.active,
                    show: true,
                }, () => {
                    if (response.messages.length > 1) {
                        this.setFadedInterval();
                    }
                    if (response.fixedMessage.active){
                        this.props.setFixedUspVisible();
                    }
                    
                });
            });
    }

    setFadedInterval() {
        setInterval(() => {
            this.setState({ show: false }, this.triggerCSSTransitionExitEffect);
        }, 4000);
    }
    
    triggerCSSTransitionExitEffect() {
        setTimeout(() => {
            this.setState({
                selectedMessageIndex: this.calculateNextMessageIndex(),
                show: true,
            });
        }, this.fadeTimeout);
    }

    calculateNextMessageIndex() {
        const nextMessageIndex = this.state.selectedMessageIndex + 1;
        return nextMessageIndex > (this.state.messages.length - 1) ? 0 : nextMessageIndex;
    }

    render() {

        if (this.state.messages.length) {
            const message = this.state.messages[this.state.selectedMessageIndex];
            // const fixedMessage = this.state.fixedMessage;

            return (
                <div className='usp-container'>
                    <CSSTransition in={this.state.show} classNames='marquee-animation' timeout={this.fadeTimeout}>
                        <Anchor url={message.link}>{message.text}</Anchor>
                    </CSSTransition>
                    {/* { this.state.showFixedMessage && <Anchor className='fixed-message' url={fixedMessage.link}>{fixedMessage.text}</Anchor>} */}
                </div >
            );
        }

        return null;
    }
}

Usp.propTypes = {
    headerApi: PropTypes.shape({
        getUsp: PropTypes.func.isRequired
    }),
    setUspVisible: PropTypes.func.isRequired,
    setFixedUspVisible: PropTypes.func.isRequired
};

export default Usp;