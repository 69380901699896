/* eslint-disable no-invalid-this */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { WarningMsg } from 'shared/components/Messages/Messages';
import { bbTracking } from 'shared/components/DataLayer/DataLayer';
import { InputWithButton } from 'shared/components/Input/InputWithButton';
import { Anchor } from 'shared/components/Anchor/Anchor';

import { ReactComponent as Twitter } from 'shared/svg/social-twitter-filled.svg';
import { ReactComponent as Facebook } from 'shared/svg/social-facebook-filled.svg';
import { ReactComponent as YouTube } from 'shared/svg/social-youtube-filled.svg';
import { ReactComponent as Instagram } from 'shared/svg/social-instagram-filled.svg';
import { ReactComponent as Pintrest } from 'shared/svg/social-pintrest-filled.svg';
import { ReactComponent as TikTok } from 'shared/svg/tik-tok-filled.svg';

import '../../styles/shared/SocialSection.scss';

class SocialSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            validEmail: false,
            submitted: false,
            didBlur: false
        };

        this.faceBookUrl = this.props.isMena ? 'https://www.facebook.com/BEAUTYBAYArabia' : 'https://www.facebook.com/BeautyBaycom';
        this.instagramUrl = this.props.isMena ? 'https://www.instagram.com/beautybayarabia/?hl=en' : 'https://instagram.com/beautybaycom';
    }

    signUp = (event, newsletterSignUp) => {
        event.preventDefault();

        if (!this.state.validEmail || this.state.submitted) {
            return null;
        }
        // eslint-disable-next-line no-invalid-this
        newsletterSignUp(this.state.email, 'website_general')
            .then(() => {
                this.setState({ submitted: true });
                const newsletterTrackingData = { event: 'newsletter', eventCategory: 'Newsletter Sign Up', action: 'Connect With Us', label: 'Opted In', eventId: '8489' };
                bbTracking(window.location.href, newsletterTrackingData);
            });
    }

    validateEmail = (event) => {
        this.setState({ didBlur: true, validEmail: (event.target.checkValidity() && !!event.target.value) });
    }

    render() {
        return (

            <div className='social-section'>
                <div className='social-section__section'>
                    <h3>{this.props.t('common:signUpForNew')}</h3>
                    <div className='input-wrapper'>
                        {
                            this.state.submitted
                                ? <p>{this.props.t('header:signupSuccessMessage')}</p>
                                : <Fragment>
                                    <InputWithButton type='email' placeholder={this.props.t('common:emailPlaceholder')} value={this.state.email} onChange={(e) => this.setState({ didBlur: false, email: e.target.value })} onBlur={this.validateEmail} buttonText={this.props.t('common:signUp')} onClick={(e) => this.signUp(e, this.props.signUpToNewsletter)} />
                                    {this.state.didBlur && this.state.email && !this.state.validEmail ? <WarningMsg message={this.props.t('emailWarning')} /> : null}
                                </Fragment>
                        }
                    </div>
                </div>
                <div className='social-section__section'>
                    <h3>{this.props.t('common:connectWithUs')}</h3>
                    <div className='social-icons'>
                        {!this.props.isMena && <Anchor className='social-link' url='https://www.tiktok.com/@beautybay' target='_blank'>
                            <TikTok className='social-tiktok' />
                            <p>{this.props.t('header:socialIcons.tikTok')}</p>
                        </Anchor>}
                        {!this.props.isMena && <Anchor className='social-link' url='https://twitter.com/intent/follow?source=followbutton&variant=1.0&screen_name=beautybay' target='_blank'>
                            <Twitter className='social-twitter' />
                            <p>{this.props.t('header:socialIcons.twitter')}</p>
                        </Anchor>}
                        <Anchor className='social-link' url={this.faceBookUrl} target='_blank'>
                            <Facebook className='social-facebook' />
                            <p>{this.props.t('header:socialIcons.facebook')}</p>
                        </Anchor>
                        {!this.props.isMena && <Anchor className='social-link' url='http://www.youtube.com/user/wwwbeautybaycom' target='_blank'>
                            <YouTube className='social-youtube' />
                            <p>{this.props.t('header:socialIcons.youtube')}</p>
                        </Anchor>}
                        <Anchor className='social-link' url={this.instagramUrl} target='_blank'>
                            <Instagram className='social-instagram' />
                            <p>{this.props.t('header:socialIcons.instagram')}</p>
                        </Anchor>
                        {!this.props.isMena && <Anchor className='social-link' url='https://pinterest.com/beautybay/' target='_blank'>
                            <Pintrest className='social-pintrest' />
                            <p>{this.props.t('header:socialIcons.pintrest')}</p>
                        </Anchor>}
                    </div>
                </div>
            </div>

        );
    }
}

SocialSection.propTypes = {
    t: PropTypes.func.isRequired,
    signUpToNewsletter: PropTypes.func.isRequired,
    isMena: PropTypes.bool,
};

export default withTranslation(['header', 'common'])(SocialSection);
