
/* eslint-disable no-undef */
import React, { Fragment } from 'react';
import propTypes from 'prop-types';

import SearchSuggestions from './SearchSuggestions';
import CuratedSearchContent from './CuratedSearchContent';
import SearchInput from './SearchInput';
import debounce from 'lodash/debounce';

import { ReactComponent as CloseIcon } from 'shared/svg/close.svg';

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            showResults: false,
            curatedContent: null,
            terms: []
        };
        this.handleSearchSuggestions = this.handleSearchSuggestions.bind(this);
        this.getAutocompleteOptions = debounce(searchTerm => {
            return this.props.getAutocompleteOptions(searchTerm)
                .then(result => {
                    const terms = result.suggestions.map(suggestion => ({ term: suggestion.searchTerm }) );
                    this.handleSearchSuggestions(terms);
                });
        }, 300);
    }

    onInputChange(e) {
        const value = e.currentTarget.value;
        this.setState({ searchTerm: value });
        if (this.state.searchTerm) {
            this.getAutocompleteOptions(this.state.searchTerm);
        } else {
            this.setState({ terms: [] });
        }
    }

    handleSearchSuggestions(terms, products) {
        this.setState({ products, terms, showResults: true });
    }

    submitSearch(e) {
        e.preventDefault();
        const searchTerm = this.state.searchTerm;
        if (searchTerm !== '') {
            this.setState({ searchTerm: '' }, () => {
                this.props.closeMenu();
                let route = `/l/?q=${searchTerm}`;
                if (this.props.user.language !== 'en') {
                    route = `/${this.props.user.language}/l/?q=${searchTerm}`;
                }
                this.props.history.push(this.props.routeResolver.resolve(route));
                window.location.href = route;
            });
        }
    }

    clearSearch() {
        this.setState({ searchTerm: '' });
    }

    closeMenu() {
        this.clearSearch();
        this.props.closeMenu();
    }

    componentDidMount() {
        this.props.getSearchLinksData()
            .then(searchLinkData => this.setState({ curatedContent: searchLinkData }));
    }

    setInputRef(input) {
        this.textInput = input;
    }

    render() {
        return (
            <Fragment>
                <div className='search-container' onClick={(e) => e.stopPropagation()}>
                    <div className='search-container__content'>
                        <div className='search-container__input'>
                            <SearchInput
                                submitSearch={(e) => { this.submitSearch(e); }}
                                clearSearch={this.clearSearch.bind(this)}
                                searchTerm={this.state.searchTerm}
                                onChange={this.onInputChange.bind(this)}
                                inputRef={this.setInputRef.bind(this)}
                                closeMenu={this.closeMenu.bind(this)}
                                mobile={this.props.mobile}
                            />
                        </div>
                        {
                            this.state.curatedContent &&
                            <CuratedSearchContent content={this.state.curatedContent} terms={this.state.terms} searchTerm={this.state.searchTerm} closeMenu={this.closeMenu.bind(this)}>
                                <SearchSuggestions
                                    showResults={this.state.showResults}
                                    searchTerm={this.state.searchTerm}
                                    terms={this.state.terms}
                                    closeMenu={this.closeMenu.bind(this)}
                                />
                            </CuratedSearchContent>
                        }
                    </div>
                    <div className='search-container__close' onClick={this.closeMenu.bind(this)}>
                        <CloseIcon className='icon close-icon' />
                    </div>
                </div>
            </Fragment>
        );
    }
}

Search.propTypes = {
    closeMenu: propTypes.func.isRequired,
    history: propTypes.object.isRequired,
    user: propTypes.object.isRequired,
    getSearchLinksData: propTypes.func.isRequired,
    routeResolver: propTypes.object,
    mobile: propTypes.bool,
    getAutocompleteOptions: propTypes.func.isRequired
};

export default Search;