/* eslint-disable no-undef */
import React from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import '../../styles/shared/CompanyInfo.scss';

const CompanyInfo = ({ t }) => {
    return (
        <div className='company-info'>
            <p>{t('header:copyright')}</p>
            <p>{t('header:companyRegistration')}</p>
        </div>
    );
};

CompanyInfo.propTypes = {
    t: propTypes.func.isRequired
};

export default withTranslation(['header'])(CompanyInfo);