/* eslint-disable indent */
import React, { Fragment } from 'react';
import propTypes from 'prop-types';

import { Anchor } from 'shared/components/Anchor/Anchor';
import { ReactComponent as SearchIcon } from 'shared/svg/searchbox.svg';

export const CuratedSearchLinks = ({ links, closeMenu }) => {
    return (
        <Fragment>
            {
                links ? <div className='curated-search__links'>
                    {links ? links.map((link, i) => <Anchor key={i} url={link.url} onClick={() => closeMenu()} trackingData={{ event: 'search-popular-searches', link: link.linkTitle }}><SearchIcon />{link.linkTitle}</Anchor>) : null}
                </div>
                    : null
            }
        </Fragment>
    );
};

CuratedSearchLinks.propTypes = {
    links: propTypes.array.isRequired,
    closeMenu: propTypes.func.isRequired
};

export default CuratedSearchLinks;
