/* eslint-disable no-undef */
import React from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import SeoMenu from './SeoMenu';
import { Anchor } from 'shared/components/Anchor/Anchor';

import '../../styles/mobile/ShopSection.scss';

const ShopSection = ({ menuContent, toggleShop }) => {

    const showShopLevel = (content) => {
        toggleShop(content, true);
    };

    return (
        <nav className='shop-section'>
            <ul className='shop-section__featured-items'>
                {menuContent && menuContent.shop.featuredShopItems.map((item, index) => {
                    const trackingDataFeaturedLink = { event: 'mobileMenuLink', category: 'Mobile Menu', action: 'Mobile Menu Click', label: item.title };

                    return (
                        <li key={index}>
                            <Anchor trackingData={trackingDataFeaturedLink} className='feature-shop-item' onClick={() => showShopLevel(item.level)} style={item.highlightColour && { color: `${item.highlightColour}` }}>
                                {item.title}
                            </Anchor>
                            <SeoMenu seoContent={item.level} />
                        </li>
                    );
                })}
            </ul>
            <ul className='shop-section__items'>
                {menuContent && menuContent.shop.shopItems.map((item, index) => {
                    const trackingDataLink = { event: 'mobileMenuLink', category: 'Mobile Menu', action: 'Mobile Menu Click', label: item.title };

                    return (
                        <li key={index}>
                            <Anchor trackingData={trackingDataLink} className='shop-item' onClick={() => showShopLevel(item.level)} style={item.highlightColour && { color: `${item.highlightColour}` }}>
                                {item.title}
                            </Anchor>
                            <SeoMenu seoContent={item.level} />
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

ShopSection.propTypes = {
    menuContent: propTypes.object.isRequired,
    toggleShop: propTypes.func.isRequired
};

export default withTranslation('header')(ShopSection);