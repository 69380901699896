/* eslint-disable no-undef */
import fetch from 'fetch-retry';
import Cookies from 'js-cookie';
import { enableAuth0 } from '../../featureToggles';
import { v4 as uuid } from 'uuid';

import heartbeatApi from '../heartbeatApi';
class Fetch {
    setTokenGenerator(tokenGenerator) {
        this.tokenGenerator = tokenGenerator;
    }

    getToken() {
        return this.tokenGenerator();
    }

    _fetch(url, method, body = null, allowUnauthed = false) {
        return Promise.all([this.getAuthHeader(allowUnauthed)])
            .then(([authHeader]) => {
                const bodyOption = body ? { body: JSON.stringify(body) } : {};
                const options = Object.assign({
                    method,
                    credentials: 'same-origin',
                    withCredentials: true,
                    headers: this.buildHeaders(authHeader),
                    retryOn: [500, 502, 503, 504],
                    retries: 1,
                    retryDelay: 100
                }, bodyOption);

                const queryParams = this.buildQueryParams();
                return fetch(this.buildUrl(url, queryParams), options)
                    .then(response => {
                        if (response.ok || (response.status === 404 && response.headers.get('content-type').startsWith('application/json'))) {
                            return response;
                        }
                        else {
                            const message = `Fetch to ${url} with method ${method} failed with status code ${response.status}`;
                            console.error(`${message} console.error`); // eslint-disable-line no-console
                            const error = new Error(message);
                            if (response.status > 499 && window.newrelic) {
                                window.newrelic.noticeError(error, { url, method, status: response.status });
                            }

                            throw error;
                        }
                    });
            });
    }

    buildUrl(url, queryParams) {
        return `${url}${url.includes('?') ? '&' : '?' }${new URLSearchParams(queryParams)}`;
    }

    buildQueryParams() {
        const locale = Cookies.get('Locale') || 'en-GB';
        const localStorageBagId = window.localStorage.getItem('bagId');
        let bagId;
        if (localStorageBagId) {
            bagId = localStorageBagId;
        } else {
            // code for when guest tokens are no longer issued
            const newBagId = uuid();
            bagId = newBagId;
            window.localStorage.setItem('bagId', newBagId);
        }
        return {
            locale: locale,
            bagId 
        };
    }

    buildHeaders(authHeader) {
        const headers = {
            'Content-Type': 'application/json',
            ['Auth0-Enabled']: enableAuth0
        };
        if (authHeader) {
            headers.Authorization = authHeader;
        }
        return headers;
    }

    getAuthHeader(allowUnauthed = false) {
        if (enableAuth0) {
            return this.getToken()
                .then(token => `Bearer ${token}`)
                .catch(() => null);
        } else {
            const authToken = Cookies.get('AuthToken');

            if (authToken || allowUnauthed) {
                return Promise.resolve(`Bearer ${authToken}`);
            }
            else {
                return heartbeatApi.heartbeat()
                    .then(() => {
                        const refreshedAuthToken = Cookies.get('AuthToken');
                        return `Bearer ${refreshedAuthToken}`;
                    });
            }
        }
    }
}

export const fetchClass = new Fetch();

export const get = (url) => {
    return fetchClass._fetch(url, 'GET');
};

export const getUnauthed = (url) => {
    return fetchClass._fetch(url, 'GET', null, true);
};

export const put = (url, body = null) => {
    return fetchClass._fetch(url, 'PUT', body);
};

export const post = (url, body = null) => {
    return fetchClass._fetch(url, 'POST', body);
};

export const del = (url) => {
    return fetchClass._fetch(url, 'DELETE');
};
