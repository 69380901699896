import React from 'react';
import PropTypes from 'prop-types';
import { bbTracking } from 'shared/components/DataLayer/DataLayer';

export const Anchor = React.memo(({ url, onClick = null, children, trackingData = {}, ...props }) => {

    if(!url){
        return <span {...props} className={props.className ? `${props.className} anchor-no-link` : 'anchor-no-link'} onClick={(e) => { handleAnchorClick(e, onClick, trackingData); }}>{children}</span>;
    }

    return <a {...props} onClick={(e) => { handleAnchorClick(e, onClick, trackingData); }} href={url} >{children}</a>;
});

const handleAnchorClick = (e, clickAction, trackingData) => {
    bbTracking(window.location.href, trackingData);
    return clickAction ? clickAction(e) : null;
};

Anchor.propTypes = {
    url: PropTypes.string,
    children: PropTypes.any.isRequired,
    onClick: PropTypes.func,
    trackingData: PropTypes.object,
    className: PropTypes.string,
};
Anchor.displayName = 'Anchor';
