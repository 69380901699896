import React from 'react';
import propTypes from 'prop-types';
import { Media } from 'react-breakpoints';

export const Breakpoint = ({DesktopComponent, MobileComponent, ...props}) => (
    <Media>
        {({ breakpoints, currentBreakpoint }) =>
            breakpoints[currentBreakpoint] > breakpoints.tablet ? <DesktopComponent {...props} /> : <MobileComponent {...props} />
        }
    </Media>
);

Breakpoint.propTypes = {
    DesktopComponent: propTypes.func.isRequired,
    MobileComponent: propTypes.func.isRequired
};