
import CuratedSearchLinks from './CuratedSearchLinks';
import React from 'react';
import propTypes from 'prop-types';

import PageLoadingSpinner from 'shared/components/Loading/PageLoadingSpinner';

import './styles/curatedSearchContent.scss';

const CuratedSearchContent = ({ content, children, terms, searchTerm, closeMenu }) => {
    return (
        <div className='curated-search__wrapper'>
            {content ?
                <div className='curated-search__content'>
                    {terms.length && searchTerm !== '' ? children : <CuratedSearchLinks links={content.links} closeMenu={closeMenu} />}
                </div>
                : <PageLoadingSpinner />
            }
        </div>
    );
};

CuratedSearchContent.propTypes = {
    content: propTypes.object,
    children: propTypes.node.isRequired,
    terms: propTypes.array.isRequired,
    searchTerm: propTypes.string.isRequired,
    closeMenu: propTypes.func.isRequired,
};

export default CuratedSearchContent;
