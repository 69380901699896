import { getHeaderContent, getHeaderLinks, getHeaderMenu, getHeaderUserData, getUsp } from './headerApi';
import { addItemsToBag, addToBag } from './bagApi';
import { getSearchLinks, getAutocomplete } from './searchApi';
import { newsletterSignUp } from './accountApi';
import wishlistApi from './wishlistApi';
import accountApi from './accountApiV2';
import { getCountries } from './deliveryApi';


export default {
    getHeaderMenu,
    getHeaderUserData,
    getHeaderLinks,
    getSearchLinks,
    getAutocomplete,
    newsletterSignUp,
    getUsp,
    addToBag,
    addItemsToBag,
    wishlistApi,
    getCountries,
    accountApi,
    getHeaderContent
};