import React from 'react';
import { Anchor } from 'shared/components/Anchor/Anchor';
import { ReactComponent as BeautyBayLogoStacked } from 'shared/svg/logo-stacked.svg';

class Logo extends React.Component {
    render() {
        return (
            <Anchor className='header-logo' url='/'>
                <BeautyBayLogoStacked />
            </Anchor>
        );
    }
}

export default Logo;