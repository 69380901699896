import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import FredhopperSearch from './FredhopperSearch';
import RouteResolver from '../../RouteResolver';

import './styles/searchWrapper.scss';
const SearchWrapper = ({ user, history, closeMenu, getSearchLinksData, getAutocompleteOptions, mobile }) => {
    return (
        <Fragment>
            <FredhopperSearch user={user} routeResolver={RouteResolver} history={history} closeMenu={closeMenu} getSearchLinksData={getSearchLinksData} getAutocompleteOptions={getAutocompleteOptions} mobile={mobile}/>
        </Fragment>
    );
};

SearchWrapper.propTypes = {
    history: propTypes.object,
    user: propTypes.object,
    mobile: propTypes.bool,
    closeMenu: propTypes.func.isRequired,
    getSearchLinksData: propTypes.func.isRequired,
    fredhopperEnabled: propTypes.bool,
    getAutocompleteOptions: propTypes.func.isRequired
};

export default SearchWrapper;