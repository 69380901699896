/* eslint-disable no-undef */
import React from 'react';
import propTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'shared/svg/close.svg';

import { HeaderConsumer } from '../../../../headerContext';
import InformationSection from './InformationSection';
import SocialSection from '../shared/SocialSection';
import ShopSection from './ShopSection';

import '../../styles/mobile/MobileMenu.scss';

const MobileMenu = ({ closeMenu, getCountries, userData, menuContent, toggleMobileMenuLevel2, menuContainerRef, location }) => {
    return (
        <HeaderConsumer>
            {
                ({ signUpToNewsletter, isMena }) => (
                    <div className='mobile-menu' onClick={closeMenu}>
                        <div ref={menuContainerRef} className='menu-container' onClick={(e) => e.stopPropagation()}>
                            <div className='menu-container__header'>
                                <div className='menu-container__header--close' onClick={closeMenu}>
                                    <CloseIcon className='icon close-icon' />
                                </div>
                            </div>
                            <div className='menu-container__main'>
                                <ShopSection closeMenu={closeMenu} menuContent={menuContent} toggleShop={toggleMobileMenuLevel2} />
                                <InformationSection location={location} getCountries={getCountries} userData={userData} toggleInformationAndOther={toggleMobileMenuLevel2} menuContent={menuContent} />
                                <SocialSection signUpToNewsletter={signUpToNewsletter} isMena={isMena} />
                            </div>
                        </div>
                    </div>
                )
            }
        </HeaderConsumer>
    );
};

MobileMenu.propTypes = {
    getCountries: propTypes.func.isRequired,
    toggleMobileMenuLevel2: propTypes.func.isRequired,
    userData: propTypes.object.isRequired,
    menuContent: propTypes.object.isRequired,
    menuContainerRef: propTypes.object,
    closeMenu: propTypes.func,
    location: propTypes.object
};

export default MobileMenu;