/* eslint-disable indent */
/* global apis:readonly, featureToggles:readonly, process:readonly */ //eslint-disable-line
import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import './App.scss';
import propTypes from 'prop-types';
import Header from './apps/header/Header';
import { withRouter } from 'react-router';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import { HeaderProvider } from './headerContext';
import { setPreRenderReadyState } from 'shared/components/PreRender';
import { pushUserDataIntoDataLayer } from './shared/components/DataLayer/DataLayer';
import { HelmetProvider } from 'react-helmet-async';
import LocaleRoute from './LocaleRoute';
import accountApiV2 from './api/accountApiV2';
import api from './api';
import { getUserInfo, getA0UserInfo } from './api/lib/getUserInfo';
import { UserProvider } from './userContext';

// Only applicable for Edited
import './apps/header/styles/desktop/DesktopStyleOverrides.scss';
import './apps/header/styles/mobile/MobileStyleOverrides.scss';
import '../src/shared/styles/headerOverrides.scss';
import { enableAuth0 } from './featureToggles';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading: true,
            AttraqtLoaded: true,
            errorState: false,
            showShippingPreference: false,
            isUspVisible: false,
            isFixedUspVisible: false,
            countries: [],
            isRtl: false,
            isAppRequest: false,
            brandsMenuOpen: false
        };
        this.attraqtTracking = null;
        this.setBrandsOpen = this.setBrandsOpen.bind(this);
        this.helmetContext = {
            type: 'text/javascript',
            src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
            async: true
        };
    }

    componentDidMount() {
        if (window && window.QueueIt) {
            window.QueueIt.validateUser(true);
        }
        return Promise.all([
            accountApiV2.getCountries(),
            enableAuth0 ? getA0UserInfo(this.props.auth0.user) : getUserInfo()
        ])
            .then(([countries, userInfo]) => {
                this.setIsRtl(userInfo);
                this.setHtmlLanguage(userInfo);
                this.setPageDirection();
                this.isAppRequest();
                this.setState({
                    countries: countries,
                    user: userInfo,
                    loading: false,
                }, () => {
                    return this.setUpGtm(userInfo.countryCode);
                });

                this.props.history && this.props.history.listen(() => {
                    if (window && window.QueueIt) {
                        window.QueueIt.validateUser(true);
                    }
                    if (this.state.errorState) {
                        this.setState({ errorState: false });
                    }

                    this.setUpGtm(userInfo.countryCode);
                    this.setIsRtl(userInfo);
                    this.setHtmlLanguage(userInfo);
                    this.setPageDirection();
                    this.isAppRequest();
                });

                setPreRenderReadyState(false);
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    componentDidUpdate(prevProps) {
        if ((enableAuth0 && (!prevProps.auth0.user && !!this.props.auth0.user))) {
            getA0UserInfo(this.props.auth0.user)
                .then(userInfo => {
                    this.setState({
                        ...this.state,
                        user: userInfo
                    });
                });
        }
    }

    componentDidCatch(error) {
        if (window.newrelic) {
            window.newrelic.noticeError(error);
        }
        this.setState({ errorState: true });
    }

    getPriceBand(countryCode) {
        const countryDetails = this.state.countries.filter(country => country.countryCode === countryCode);
        return countryDetails[0] ? countryDetails[0].priceBand.toUpperCase() : 'UK';
    }

    setHtmlLanguage(userInfo) {
        const currentLanguage = document.documentElement.getAttribute('lang');
        if (currentLanguage !== userInfo.language) {
            document.documentElement.setAttribute('lang', userInfo.language);
            document.body.classList.add(userInfo.language);
        }
    }

    setPageDirection() {
        if (this.state.isRtl) {
            document.documentElement.setAttribute('dir', 'rtl');
        }
    }

    isAppRequest() {
        if (window.location.href.indexOf('app=true') !== -1) {
            this.setState({
                isAppRequest: true
            });
        }
    }

    setBrandsOpen(activeItem) {
        const siteWrapper = document.getElementById('all-site-wrapper');
        if (activeItem === 'Brands') {
            siteWrapper.classList.add('brands-open-top-wrapper');
            this.setState({brandsMenuOpen: true});
        } else {
            this.setState({brandsMenuOpen: false});
            siteWrapper.classList.remove('brands-open-top-wrapper');
        }
    }

    render() {
        let className = this.state.isUspVisible ? 'wrapper usp-active' : 'wrapper';
        if (this.state.isFixedUspVisible) {
            className += ' fixed-usp-active';
        }
        if (this.state.brandsMenuOpen) {
            className += ' brands-open'; 
        }

        return (
            <div className={className}>
                <HelmetProvider context={this.helmetContext}>
                    <HeaderProvider getHeaderUserData={api.getHeaderUserData} getHeaderMenu={api.getHeaderMenu} addToBag={api.addToBag} addItemsToBag={api.addItemsToBag} wishlistApi={api.wishlistApi} attraqtTracking={this.attraqtTracking} newsletter={api.newsletterSignUp} user={this.state.user}>
                        <UserProvider user={this.state.user}>
                                <LocaleRoute path={'*'} render={({ location, history }) => !this.state.isAppRequest && <Header setBrandsOpen={this.setBrandsOpen} location={location} history={history} getUsp={api.getUsp} setUspVisible={() => this.setState({ isUspVisible: true })} setFixedUspVisible={() => this.setState({ isFixedUspVisible: true })} user={getUserInfo} getSearchLinksData={api.getSearchLinks} openSitePreferences={() => this.setState({ showShippingPreference: true })} getCountries={api.getCountries} getAutocompleteOptions={ api.getAutocomplete } />} />
                        </UserProvider>
                    </HeaderProvider>
                </HelmetProvider>
            </div>
        );
    }


    setUpGtm(countryCode) {
        if (this.state.user) {
            const userCountryCode = countryCode ? countryCode : this.state.user.countryCode;
            const tagManagerArgs = {
                gtmId: userCountryCode && userCountryCode.toLowerCase() === 'cn' ? 'GTM-KTV2ZKW' : 'GTM-PQFJ5BW',
                dataLayer: {}
            };

            TagManager.initialize(tagManagerArgs);
            const visitorType = Cookies.get('VisitorType') || 'new';
            pushUserDataIntoDataLayer(this.state.user, this.getPriceBand(countryCode), visitorType);
        }
    }

    setIsRtl(userInfo) {
        if (this.state.user) {
            const userLanguage = userInfo.language ? userInfo.language : 'en';
            if (userLanguage === 'ar') {
                this.setState({
                    isRtl: true
                });
            }

        }
    }
}

App.propTypes = {
    history: propTypes.object,
    auth0: propTypes.object
};

export default withAuth0(withRouter(App));