import React from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Anchor } from 'shared/components/Anchor/Anchor';

import '../../styles/desktop/InformationMenuLinks.scss';

const InformationMenuLinks = ({ t, toggleInformationMenu, selectedOption }) => {

    const connectWithUsTrackingData = { event: 'connectwithus', eventCategory: 'Connect With Us', action: 'Connect Us Link Click', label: 'Connect With Us', eventId: '2584' };
    const infoAndOtherTrackingData = { event: 'aboutus', eventCategory: 'About Us', action: 'About Us Link Click', label: 'About Us', eventId: '2584' };

    return (
        <div className='desktop-header__info-bar--information-menu-links'>
            <Anchor
                trackingData={infoAndOtherTrackingData}
                className={`information-menu-links__info-and-other ${selectedOption === 'infoAndOther' ? 'active' : ''}`}
                onClick={() => toggleInformationMenu('infoAndOther')}
                onMouseEnter={selectedOption && selectedOption !== 'infoAndOther' ? () => toggleInformationMenu('infoAndOther') : null}
            >
                {t('header:informationSection.informationAndOther')}
            </Anchor>
            <Anchor
                trackingData={connectWithUsTrackingData}
                className={`information-menu-links__connect-with-us ${selectedOption === 'connectWithUs' ? 'active' : ''}`}
                onClick={() => toggleInformationMenu('connectWithUs')}
                onMouseEnter={selectedOption && selectedOption !== 'connectWithUs' ? () => toggleInformationMenu('connectWithUs') : null}
            >
                {t('common:connectWithUs')}
            </Anchor>
        </div>
    );
};

InformationMenuLinks.propTypes = {
    t: propTypes.func.isRequired,
    toggleInformationMenu: propTypes.func.isRequired,
    selectedOption: propTypes.string
};

export default withTranslation(['header', 'common'])(InformationMenuLinks);