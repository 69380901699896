/* eslint-disable no-undef */
import React from 'react';
import propTypes from 'prop-types';

import MobileMenuHeader from './MobileMenuHeader';
import MobileMenuContentLevel3 from './MobileMenuContentLevel3';

const MobileMenuLevel3 = ({ closeMenu, content, toggleMobileMenuLevel3, toggleSearch, featuredLinks }) => {
    return (
        <div className='mobile-menu' onClick={closeMenu}>
            <div className='menu-container' onClick={(e) => e.stopPropagation()}>
                <MobileMenuHeader toggleMobileMenuLevel={toggleMobileMenuLevel3} shop={true} toggleSearch={toggleSearch}/>
                {content && <MobileMenuContentLevel3 content={content} featuredLinks={featuredLinks} />}
            </div>
        </div>
    );
};

MobileMenuLevel3.propTypes = {
    closeMenu: propTypes.func.isRequired,
    content: propTypes.array,
    toggleMobileMenuLevel3: propTypes.func.isRequired,
    toggleSearch: propTypes.func.isRequired,
    featuredLinks: propTypes.bool
};

export default MobileMenuLevel3;