import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import User from '../../shared/user';

import accountApi from '../accountApiV2';
import loyaltyApi from '../loyaltyApi';
import { getUserFromToken } from './getUserFromToken';

const getAdditionalUserData = () => {
    return Promise.all([
        loyaltyApi.getLoyaltyData(),
        accountApi.getCustomerSummary(),
        accountApi.getSummaryContent()
    ]);
};

export const getUserInfo = (getAdditionalData = true) => {
    return getUserFromToken(jwt.decode(Cookies.get('AuthToken')), getAdditionalData ? getAdditionalUserData : null);
};

export const getA0UserInfo = (auth0User) => {
    return auth0User ? getAdditionalUserData()
        .then(([tribeDetails, customerOrderSummary, customerInfo]) => {
            return new User().withAuth0UserInfo(auth0User).withTribe(tribeDetails).withUserOrderSummary(customerOrderSummary).withCustomerInfo(customerInfo);
        }) : new User().withAuth0GuestUserInfo();
};