import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Breakpoint } from 'shared/components/Breakpoint';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

import './styles/Header.scss';

const Header = ({ setUspVisible, getUsp, setFixedUspVisible, location, getSearchLinksData, history, openSitePreferences, getCountries, setBrandsOpen, user, getAutocompleteOptions }) => {

    const [headerUserData, setHeaderUserData] = useState({});

    useEffect(() => {
        user()
            .then(userData => {
                setHeaderUserData(userData);
            });
    }, []);

    const isOnBagPage = () => {
        const pathname = location.pathname;
        return pathname && pathname.startsWith('/bag/');
    };

    return (
        <Breakpoint
            DesktopComponent={DesktopHeader}
            MobileComponent={MobileHeader}
            setUspVisible={setUspVisible}
            getUsp={getUsp}
            setFixedUspVisible={setFixedUspVisible}
            isOnBagPage={isOnBagPage()}
            getSearchLinksData={getSearchLinksData}
            history={history}
            location={location}
            openSitePreferences={openSitePreferences}
            getCountries={getCountries}
            unfixHeader={true}
            setBrandsOpen={setBrandsOpen}
            user={headerUserData}
            getAutocompleteOptions={getAutocompleteOptions}
        />
    );
};

Header.propTypes = {
    setUspVisible: propTypes.func,
    setFixedUspVisible: propTypes.func,
    getUsp: propTypes.func,
    history: propTypes.object.isRequired,
    user: propTypes.object.isRequired,
    location: propTypes.object.isRequired,
    getSearchLinksData: propTypes.func.isRequired,
    openSitePreferences: propTypes.func.isRequired,
    getCountries: propTypes.func.isRequired,
    setBrandsOpen: propTypes.func.isRequired,
    getAutocompleteOptions: propTypes.func.isRequired
};

export default Header;
