import React from 'react';
import propTypes from 'prop-types';

import './Button.scss';
import { Anchor } from '../Anchor/Anchor';

export const Button = React.forwardRef(({ text, onClick, type = 'primary', loading = false, disabled = false, href, target, rel, dataClipboardText }, ref) => {
    return (
        <div className='button-container'>
            {loading && <Spinner buttonRole={type} />}
            {!loading && React.createElement(href ? Anchor : 'button', {
                className: `button-${type}`, 
                disabled, 
                onClick,
                url: href,
                rel,
                target,
                ref,
                'data-clipboard-text': dataClipboardText,
            }, text)}
        </div>
    );
});

Button.displayName = 'Button';

const Spinner = ({ buttonRole }) => (
    <div className={`button-spinner-${buttonRole}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

Spinner.propTypes = {
    buttonRole: propTypes.string.isRequired
};

Button.propTypes = {
    text: propTypes.string.isRequired,
    onClick: propTypes.func,
    type: propTypes.string,
    href: propTypes.string,
    rel: propTypes.string,
    target: propTypes.string,
    loading: propTypes.bool,
    disabled: propTypes.bool,
    dataClipboardText: propTypes.string
};