import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { RemoveScroll } from 'react-remove-scroll';
import RouteResolver from 'RouteResolver';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import Usp from './components/shared/Usp';
import Logo from './components/shared/Logo';
import MobileMenu from './components/mobile/MobileMenu';
import HeaderSearch from './components/shared/HeaderSearch';
import MobileMenuLevel2 from './components/mobile/MobileMenuLevel2';
import MobileMenuLevel3 from './components/mobile/MobileMenuLevel3';
import SearchBar from './components/shared/SearchBar';
import { Anchor } from 'shared/components/Anchor/Anchor';
import { HeaderConsumer } from '../../headerContext';

import { ReactComponent as MenuIcon } from 'shared/svg/menu-alt.svg';
import { ReactComponent as AvatarIcon } from 'shared/svg/avatar.svg';
import { ReactComponent as WishlistIcon } from 'shared/svg/wishlist.svg';
import { ReactComponent as BagIcon } from 'shared/svg/bag.svg';

import './styles/mobile/MobileHeader.scss';

const accountTrackingData = { event: 'top-menu-nav-icon', category: 'Top Menu Account', action: 'Top Menu Item Click', label: 'Account' };
const wishlistTrackingData = { event: 'top-menu-nav-icon', category: 'Top Menu Wishlist', action: 'Top Menu Item Click', label: 'Wishlist' };
const bagTrackingData = { event: 'top-menu-nav-icon', category: 'Top Menu Bag', action: 'Top Level Menu Click', label: 'Bag' };

const MobileHeader = ({ setUspVisible, setFixedUspVisible, getUsp, isOnBagPage, getSearchLinksData, history, openSitePreferences, getCountries, location, user, getAutocompleteOptions }) => {

    const [searchMenu, setSearchMenu] = useState({
        showSearch: false
    });

    const [showMenu, setShowMenu] = useState({
        show: false,
        closing: false
    });

    const [secondLevelMenu, setSecondLevelMenu] = useState({
        show: false,
        content: null,
        shop: false,
        closing: false
    });

    const [thirdLevelMenu, setThirdLevelMenu] = useState({
        show: false,
        content: null,
        shop: false,
        featuredLinks: false
    });

    useEffect(() => {
        closeMenu();
    }, [location]);

    const menuContainerRef = useRef(null);

    const closeMenu = () => {
        setThirdLevelMenu({
            show: false,
            content: null,
            shop: false
        });
        setSecondLevelMenu({
            show: false,
            content: null,
            shop: false
        });
        setShowMenu({
            show: false,
            closing: showMenu.show ? true : false
        });

        if (menuContainerRef) {
            menuContainerRef.current.scrollTop = 0;
        }
    };

    const toggleSearch = () => {
        setSearchMenu({
            showSearch: searchMenu.showSearch ? false : true,
            focusSearch: searchMenu.focusSearch ? false : true
        });
        setTimeout(() => {
            closeMenu();
        }, 400);
    };

    const toggleMenu = () => {
        closeMenu();
        setShowMenu({
            show: showMenu.show ? false : true,
            closing: showMenu.show ? true : false
        });
    };

    const toggleMobileMenuLevel2 = (content, shop) => {
        setSecondLevelMenu({
            show: secondLevelMenu.show ? false : true,
            content: content ? content : null,
            shop: shop || false,
            closing: (secondLevelMenu.show && !secondLevelMenu.closing) ? true : false
        });
    };

    const toggleMobileMenuLevel3 = (content, shop, featuredLinks) => {
        setThirdLevelMenu({
            show: thirdLevelMenu.show ? false : true,
            content: content ? content : null,
            shop: shop || false,
            featuredLinks: featuredLinks || false
        });
    };

    const menuClassName = classnames({
        'mobile-header__menu': true,
        'second-open': secondLevelMenu.show,
        'open': showMenu.show,
        'closing': showMenu.closing,
        'second-closing': secondLevelMenu.closing
    });

    useEffect(() => {
        setTimeout(() => {
            setShowMenu({
                show: showMenu.show,
                closing: false
            });
        }, 100);
    }, [showMenu.closing]);

    useEffect(() => {
        setTimeout(() => {
            setSecondLevelMenu({
                closing: false
            });
        }, 100);
    }, [secondLevelMenu.closing]);

    return (
        <HeaderConsumer>
            {
                ({ bag, wishlist, menuContent }) => (
                    <header id='beautybay-header' className='header-wrapper mobile-header'>
                        <div className='mobile-header__usp'>
                            <Usp headerApi={{ getUsp: getUsp }} setUspVisible={setUspVisible} setFixedUspVisible={setFixedUspVisible} />
                        </div>
                        <div className='mobile-header__main'>
                            <div className='mobile-header__main--left'>
                                <div className='icon-container menu-icon' onClick={toggleMenu}>
                                    <MenuIcon />
                                </div>
                                <div className='icon-container account-icon'>
                                    <Anchor url={user.loggedIn ? RouteResolver.resolve('/account/') : `/login/?returnUrl=${RouteResolver.resolve('/account/')}`} trackingData={accountTrackingData} rel='nofollow' >
                                        <AvatarIcon />
                                    </Anchor>
                                </div>
                            </div>
                            <Logo />
                            <div className='mobile-header__main--right'>
                                <div className='icon-container wishlist-icon'>
                                    <Anchor url='/wishlist/' trackingData={wishlistTrackingData} rel='nofollow' >
                                        <WishlistIcon />
                                        {
                                            wishlist.itemCount > 0 &&
                                            <span className='wishlist-icon__indicator'>{wishlist.itemCount}</span>
                                        }
                                    </Anchor>
                                </div>

                                <div className='icon-container bag-icon'>
                                    <Anchor url='/bag/' trackingData={bagTrackingData}>
                                        <BagIcon />
                                        {
                                            bag.itemCount > 0 && !isOnBagPage &&
                                            <span className='bag-icon__indicator'>{bag.itemCount}</span>
                                        }
                                    </Anchor>
                                </div>
                            </div>
                        </div>
                        <div className='mobile-header__search'>
                            <SearchBar toggleSearch={toggleSearch} secondLevel={false} userInfo={user.userInfo}/>
                        </div>
                        <RemoveScroll enabled={showMenu.show}>
                            {
                                menuContent &&
                                <div className={menuClassName}>
                                    <MobileMenu closeMenu={toggleMenu} openSitePreferences={openSitePreferences} getCountries={getCountries} userData={user} menuContent={menuContent} toggleMobileMenuLevel2={toggleMobileMenuLevel2} menuContainerRef={menuContainerRef} location={location} />
                                </div>
                            }
                            <CSSTransition in={searchMenu.showSearch} timeout={0} unmountOnExit classNames='search-transition'>
                                <div>
                                    <div className='mobile-header__search--content'>
                                        <HeaderSearch user={user} history={history} closeMenu={toggleSearch} getSearchLinksData={getSearchLinksData} mobile={true} getAutocompleteOptions={getAutocompleteOptions} />
                                    </div>
                                </div>
                            </CSSTransition>
                            <CSSTransition in={secondLevelMenu.show} timeout={0} unmountOnExit classNames='menu-transition__level'>
                                <div>
                                    <div className={`mobile-header__menu--second-level ${thirdLevelMenu.show ? 'third-open' : ''}`}>
                                        <MobileMenuLevel2 closeMenu={toggleMenu} content={secondLevelMenu.content} toggleMobileMenuLevel2={toggleMobileMenuLevel2} toggleMobileMenuLevel3={toggleMobileMenuLevel3} shop={secondLevelMenu.shop} toggleSearch={toggleSearch} />
                                    </div>
                                </div>
                            </CSSTransition>
                            <CSSTransition in={thirdLevelMenu.show} timeout={0} unmountOnExit classNames='menu-transition__level'>
                                <div>
                                    <div className={`mobile-header__menu--third-level ${thirdLevelMenu.show ? 'third-active' : ''}`}>
                                        <MobileMenuLevel3 closeMenu={toggleMenu} content={thirdLevelMenu.content} toggleMobileMenuLevel3={toggleMobileMenuLevel3} toggleSearch={toggleSearch} featuredLinks={thirdLevelMenu.featuredLinks} />
                                    </div>
                                </div>
                            </CSSTransition>
                        </RemoveScroll>
                    </header >
                )
            }
        </HeaderConsumer >
    );
};

MobileHeader.propTypes = {
    setUspVisible: propTypes.func,
    setFixedUspVisible: propTypes.func,
    getUsp: propTypes.func,
    isOnBagPage: propTypes.bool.isRequired,
    getSearchLinksData: propTypes.func.isRequired,
    history: propTypes.object,
    user: propTypes.object,
    openSitePreferences: propTypes.func.isRequired,
    getCountries: propTypes.func.isRequired,
    location: propTypes.object.isRequired,
    getAutocompleteOptions: propTypes.func.isRequired
};

export default MobileHeader;