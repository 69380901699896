import React from 'react';
import propTypes from 'prop-types';
 
const UserContext = React.createContext({ user: null });
 
export class UserProvider extends React.Component {
    render() {
        return (
            <UserContext.Provider value={this.props.user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
 
}
 
UserProvider.propTypes = {
    children: propTypes.node.isRequired,
    user : propTypes.object.isRequired
};
 
export const UserConsumer = UserContext.Consumer;