import React from 'react';
import propTypes from 'prop-types';

import RouteResolver from 'RouteResolver';

import { Anchor } from 'shared/components/Anchor/Anchor';
import { ReactComponent as AvatarIcon } from 'shared/svg/avatar.svg';
import { ReactComponent as WishlistIcon } from 'shared/svg/wishlist.svg';
import { ReactComponent as BagIcon } from 'shared/svg/bag.svg';
import { ReactComponent as EditedLogo } from 'shared/svg/edited-logo.svg';
import { ReactComponent as TribeLogo } from 'shared/svg/logo-tribe-full.svg';
import { ReactComponent as TruckIcon } from 'shared/svg/truck.svg';

import '../../styles/desktop/DesktopHeaderIcons.scss';

const DesktopHeaderIcons = ({ bag, wishlist, user, isOnBagPage}) => {
    return (
        <div className='desktop-header__icons'>
            <Anchor className='desktop-header__icons--edited-logo desktop-header__icon' trackingData={{event: 'header-icon-click', eventAction: 'Edited'}} url='/edited/'>
                <EditedLogo />
            </Anchor>
            <Anchor className='desktop-header__icons--tribe-logo desktop-header__icon' trackingData={{event: 'header-icon-click', eventAction: 'TRIBE'}} url='/account/tribe/overview/'>
                <TribeLogo />
            </Anchor>
            <Anchor className='desktop-header__icons--truck-icon desktop-header__icon' trackingData={{event: 'header-icon-click', eventAction: 'Hauls'}} url='/orders/recent/'>
                <TruckIcon />
            </Anchor>
            <Anchor className='desktop-header__icons--account-icon desktop-header__icon' trackingData={{event: 'header-icon-click', eventAction: 'My Account'}} url={user.loggedIn ? RouteResolver.resolve('/account/') : `/login/?returnUrl=${RouteResolver.resolve('/account/')}`}>
                <AvatarIcon />
            </Anchor>
            <Anchor className='desktop-header__icons--wishlist-icon desktop-header__icon' trackingData={{event: 'header-icon-click', eventAction: 'Wishlist'}} url='/wishlist/'>
                <WishlistIcon />
                {
                    wishlist.itemCount > 0 &&
                    <span className='wishlist-icon__indicator'>{wishlist.itemCount}</span>
                }
            </Anchor>
            <Anchor className='desktop-header__icons--bag-icon desktop-header__icon' trackingData={{event: 'header-icon-click', eventAction: 'Bag'}} url='/bag/'>
                <BagIcon />
                {
                    bag.itemCount > 0 && !isOnBagPage &&
                    <span className='bag-icon__indicator'>{bag.itemCount}</span>
                }
            </Anchor>
        </div>
    );
};

DesktopHeaderIcons.propTypes = {
    bag: propTypes.object.isRequired,
    wishlist: propTypes.object.isRequired,
    user: propTypes.object.isRequired,
    isOnBagPage: propTypes.bool.isRequired
};
export default DesktopHeaderIcons;