import React from 'react';
import propTypes from 'prop-types';

import CompanyInfo from '../shared/CompanyInfo';
import { Anchor } from 'shared/components/Anchor/Anchor';

import '../../styles/desktop/InformationAndOtherContent.scss';

const InformationAndOtherContent = ({ menuContent }) => {
    return (
        <div className='information-and-other-content'>
            <div className='information-and-other-content__section'>
                <CompanyInfo />
            </div>
            {menuContent && menuContent.map((section, index) => {
                return (
                    <div key={index} className='information-and-other-content__section'>
                        <h3>{section.title}</h3>
                        {section.items.map((item, itemIndex) => {
                            const itemTrackingData = { event: 'aboutus', eventCategory: 'About Us', action: 'About Us Link Sub Item Click', label: item.text, eventId: '2584' };
                            return (
                                <Anchor trackingData={itemTrackingData} key={itemIndex} url={item.link}>{item.title}</Anchor>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

InformationAndOtherContent.propTypes = {
    menuContent: propTypes.array.isRequired
};

export default InformationAndOtherContent;
