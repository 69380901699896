/* eslint-disable no-undef */
import React from 'react';
import propTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Anchor } from 'shared/components/Anchor/Anchor';
import BrandMenu from '../shared/BrandMenu';

const MobileMenuContent = ({ t, closeMenu, content, getNextLevel, scrollToLetter, activeLetter }) => {

    return (
        <div className='menu-container__main'>
            {content.map((section, index) => {
                return (
                    <div className='menu-container__main--section' key={index}>
                        <div className={`menu-container__main--section-header ${section.image ? 'with-image' : ''}`}>
                            <h3>{section.title}</h3>
                            {section.image && <img src={section.image} alt={section.title} />}
                        </div>
                        {section.viewAllLink && !content[0].brands && <Anchor className='view-all-link' url={section.viewAllLink}>{t('header:shopAll')}</Anchor>}
                        {section.featuredLinks && <Anchor onClick={() => getNextLevel(section.featuredLinks, true)} >{t('header:featured')}</Anchor>}
                        {section.items.map((item, itemIndex) => {
                            const trackingData = { event: 'mobileMenuLink', category: 'Mobile Menu - Link', action: 'Mobile Menu Click', label: item.title };

                            return (
                                <Anchor trackingData={trackingData} key={itemIndex} url={item.level ? null : item.link} onClick={item.level ? () => getNextLevel(item.level) : null} >{item.title}</Anchor>
                            );
                        })}
                    </div>
                );
            })}
            {content[0].brands && <BrandMenu closeMenu={closeMenu} brands={content[0].brands} scrollToLetter={scrollToLetter} activeLetter={activeLetter} />}
        </div>
    );
};

MobileMenuContent.propTypes = {
    t: propTypes.func.isRequired,
    closeMenu: propTypes.func.isRequired,
    content: propTypes.array,
    getNextLevel: propTypes.func.isRequired,
    scrollToLetter: propTypes.func.isRequired,
    activeLetter: propTypes.string,
};

export default withTranslation(['header'])(MobileMenuContent);