import React from 'react';
import propTypes from 'prop-types';

import { ReactComponent as EditedLogo } from 'shared/svg/edited-logo.svg';
import { Anchor } from 'shared/components/Anchor/Anchor';

const DesktopMenuContentEdited = ({ editedContent, closeMenu }) => {
    return (
        <div className='desktop-menu-content__edited-section'>
            {editedContent.title && (
                <Anchor className='desktop-menu-content__edited-section--title'>
                    {editedContent.title}
                </Anchor>
            )}
            {editedContent.items.map(
                (article, articleIndex) => {
                    const headerTrackingData = { event: 'top-menu-nav', eventCategory: 'Edited', action: article.title, label: article.title };

                    return (
                        <Anchor
                            className='desktop-menu-content__edited-section--link'
                            url={article.link}
                            key={articleIndex}
                            onClick={closeMenu}
                            trackingData={headerTrackingData}
                        >
                            {article.title}
                        </Anchor>
                    );
                }
            )}
            <EditedLogo className='desktop-menu-content__edited-section--logo' />
        </div>
    );
};

DesktopMenuContentEdited.propTypes = {
    editedContent: propTypes.object.isRequired,
    closeMenu: propTypes.func.isRequired
};

export default DesktopMenuContentEdited;