import React from 'react';
import propTypes from 'prop-types';
import { Translation } from 'react-i18next';
import { pushBagToDataLayer, bbTracking } from 'shared/components/DataLayer/DataLayer';

import without from 'lodash/without';

const HeaderContext = React.createContext({
    bag: { itemCount: 0, items: [] },
    menuContent: null,
    wishlist: { itemCount: 0, skus: [] },
    updateWishlistItemCount: () => { },
    addToBag: () => { },
    addItemsToBag: () => { },
    toggleWishlistItem: () => { },
    removeFromWishlist: () => { },
    updateTags: () => { },
    signUpToNewsletter: () => { },
    inWishlist: () => { },
    attraqtTracking: null,
    isMena: false
});

export class HeaderProvider extends React.Component {
    constructor(props) {
        super(props);
        this.attraqtTracking = this.props.attraqtTracking;
        this.state = {
            renderReady: false,
            bag: { itemCount: 0, items: [] },
            wishlist: { itemCount: 0, skus: [] },
            menuContent: null,
            updateWishlistItemCount: this.updateWishlist.bind(this),
            addToBag: this.addToBag.bind(this),
            addItemsToBag: this.addItemsToBag.bind(this),
            toggleWishlistItem: this.toggleWishlistItem.bind(this),
            removeFromWishlist: this.removeWishlistItem.bind(this),
            updateTags: this.updateTags.bind(this),
            signUpToNewsletter: this.signUpToNewsletter.bind(this),
            inWishlist: this.inWishlist.bind(this),
            isMena: false
        };
    }

    componentDidMount() {
        this.props.getHeaderUserData()
            .then(userContent => {
                this.setState(
                    { wishlist: userContent.wishlist, bag: userContent.bag },
                    () => {
                        pushBagToDataLayer(this.state.bag.items);
                    }
                );

            })
            .catch(() => this.setState({ error: true }));  

        this.props.getHeaderMenu()
            .then(menuContent => {
                this.setState({
                    renderReady: true,
                    menuContent: menuContent.menu,
                    isMena: this.isMenaCountry()
                });
            })
            .catch(() => this.setState({ error: true }));
    }

    isMenaCountry() {
        const menaCountries = ['SA', 'AE', 'QA', 'KW', 'OM', 'JO', 'BH'];
        return menaCountries.includes(this.props.user.countryCode) ;
    }

    toggleWishlistItem(sku) {
        if (this.state.inWishlist(sku)) {
            return this.removeWishlistItem(sku);
        }
        else {
            return this.props.wishlistApi.addToWishlist(sku)
                .then(result => {
                    if (result.success) {
                        this.updateWishlist(1, sku);
                        bbTracking(window.location.pathname, { event: 'add-to-wishlist', sku });

                    }
                    return result;
                });
        }
    }

    removeWishlistItem(sku) {
        return this.props.wishlistApi.removeFromWishlist(sku)
            .then(result => {
                if (result.success) {
                    this.updateWishlist(-1, sku);
                    bbTracking(window.location.pathname, { event: 'remove-from-wishlist', sku });
                }
                return result;
            });
    }

    updateTags(sku, wishlistIds) {
        return this.props.wishlistApi.updateTags(sku, wishlistIds)
            .then(result => {
                if (result.success) {
                    this.updateWishlist(1, sku);
                }
                return result;
            });
    }

    inWishlist(sku) {
        return this.state.wishlist.skus.includes(sku);
    }

    addToBag(sku, itemPrice = 0) {
        return this.props.addToBag(sku)
            .then(result => {
                const added = result.quantityAdded > 0;

                if (added) {
                    this.trackAddToBag(sku, itemPrice);
                    this.updateBag(sku, result.quantityAdded);
                }

                switch (result.type) {
                    case 'COUNTRY_RESTRICTED':
                        return {
                            added,
                            message:
                                <Translation ns='toast'>
                                    {
                                        (t) => t('countryRestricted')
                                    }
                                </Translation>,
                            maxQuantityReached: false,
                            productInfo: result.productInformation,
                            bagTotal: result.bagTotal
                        };
                    case 'OUT_OF_STOCK':
                        return {
                            added,
                            message:
                                <Translation ns='toast'>
                                    {
                                        (t) => t('outOfStockItem')
                                    }
                                </Translation>,
                            maxQuantityReached: false,
                            productInfo: result.productInformation,
                            bagTotal: result.bagTotal

                        };
                    case 'QUANTITY_AVAILABLE_EXCEEDED':
                        return {
                            added,
                            message:
                                <Translation ns='toast'>
                                    {
                                        (t) => t('stockLimitReached')
                                    }
                                </Translation>,
                            maxQuantityReached: false,
                            productInfo: result.productInformation,
                            bagTotal: result.bagTotal

                        };
                    case 'QUANTITY_EXCEEDED':
                        return {
                            added,
                            message:
                                <Translation ns='toast'>
                                    {
                                        (t) => t('limitReached')
                                    }
                                </Translation>,
                            maxQuantityReached: true,
                            productInfo: result.productInformation,
                            bagTotal: result.bagTotal

                        };
                    case 'QUANTITY_MET':
                        return {
                            added,
                            message:
                                <Translation ns='toast'>
                                    {
                                        (t) => t('addedToBag')
                                    }
                                </Translation>,
                            maxQuantityReached: true,
                            productInfo: result.productInformation,
                            bagTotal: result.bagTotal

                        };
                    case 'ADDED':
                    default:
                        return {
                            added,
                            message:
                                <Translation ns='toast'>
                                    {
                                        (t) => t('addedToBag')
                                    }
                                </Translation>,
                            maxQuantityReached: false,
                            productInfo: result.productInformation,
                            bagTotal: result.bagTotal

                        };
                }
            });
    }

    addItemsToBag(skus) {
        return this.props.addItemsToBag(skus)
            .then(result => {
                Object.keys(result).map(sku => {
                    const bagStatus = result[sku];
                    const added = bagStatus.quantityAdded > 0;

                    if (added) {
                        this.updateBag(sku, bagStatus.quantityAdded);
                    }
                });
            });
    }

    updateBag(sku, quantityAdded) {
        this.setState(state => {
            const existingItem = state.bag.items.find(item => item.sku === sku);

            if (existingItem) {
                existingItem.quantity += quantityAdded;
            }

            const newItems = existingItem ? state.bag.items : state.bag.items.concat([{ sku, quantity: quantityAdded }]);

            return {
                bag: {
                    itemCount: state.bag.itemCount + quantityAdded,
                    items: newItems
                }
            };
        }, () => pushBagToDataLayer(this.state.bag.items));
    }

    updateWishlist(quantityAdded, sku) {
        this.setState(state => {
            const skus = quantityAdded === -1 ? without(state.wishlist.skus, sku) : state.wishlist.skus.concat([sku]);
            return {
                wishlist: {
                    itemCount: state.wishlist.itemCount + quantityAdded,
                    skus,
                }
            };
        });
    }

    trackAddToBag(sku, itemPrice, quantity = 1) {
        this.attraqtTracking.sendAddToBagEvent(sku, quantity, itemPrice, window.location.pathname);
    }

    signUpToNewsletter(email, optInMethod) {
        bbTracking(window.location.pathname, { event: 'newsletter-sign-up' });
        return this.props.newsletter(email, optInMethod);
    }

    render() {
        if (!this.state.renderReady)
            return null;

        return (
            <HeaderContext.Provider value={{ ...this.state, user: this.props.user }}>
                {this.props.children}
            </HeaderContext.Provider>
        );
    }
}

HeaderProvider.propTypes = {
    children: propTypes.node.isRequired,
    getHeaderMenu: propTypes.func.isRequired,
    getHeaderUserData: propTypes.func.isRequired,
    addToBag: propTypes.func.isRequired,
    addItemsToBag: propTypes.func.isRequired,
    wishlistApi: propTypes.object.isRequired,
    attraqtTracking: propTypes.object,
    newsletter: propTypes.func.isRequired,
    user: propTypes.object.isRequired,
};

export const HeaderConsumer = HeaderContext.Consumer;
