import React from 'react';
import PropTypes from 'prop-types';
import { Anchor } from 'shared/components/Anchor/Anchor';

const BrandListing = ({ letter, brands, closeMenu, selected }) => {
    return (
        <div key={letter} className='brand-section'>
            <h3 className={`brand-section-header brand-section-header-${letter} ${selected ? 'selected' : ''}`}>
                <div>{letter}</div>
            </h3>
            <div className='brand-section-items'>
                {brands.map((brand) => <Anchor key={brand.id} url={brand.link} className='brand-item' onClick={closeMenu}>{brand.name}</Anchor>)}
            </div>
        </div>
    );
};

BrandListing.propTypes = {
    letter: PropTypes.string,
    brands: PropTypes.array,
    closeMenu: PropTypes.func,
    selected: PropTypes.bool
};

export default BrandListing;