import { getUrl } from './lib/apiUrls';
import { put, post } from './lib/fetch';

const apiUrl = getUrl('bag');

export const addToBag = (sku) => {
    return put(`${apiUrl}/${sku}`)
        .then(res => res.json());
};

export const addItemsToBag = (skus) => {
    return post(`${apiUrl}/bag`, {
        skus
    })
        .then(res => res.json());
};