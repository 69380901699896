/* eslint-disable no-undef */
import React from 'react';
import propTypes from 'prop-types';

import { ReactComponent as BackArrow } from 'shared/svg/arrow-alt-prev.svg';
import SearchBar from '../shared/SearchBar';

const MobileMenuHeader = ({ toggleMobileMenuLevel, shop, toggleSearch }) => {
    return (
        <div className='menu-container__header'>
            <div className='menu-container__header--close' onClick={() => toggleMobileMenuLevel()}>
                <BackArrow className='icon back-arrow' />
            </div>
            {shop &&
                <div className='menu-container__header--search'>
                    <SearchBar toggleSearch={toggleSearch} secondLevel={true} />
                </div>
            }
        </div>
    );
};

MobileMenuHeader.propTypes = {
    toggleMobileMenuLevel: propTypes.func.isRequired,
    toggleSearch: propTypes.func.isRequired,
    shop: propTypes.bool
};

export default MobileMenuHeader;