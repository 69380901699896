export class RouteResolver {
    constructor(location) {
        this.locales = ['fr', 'de', 'es','ar'];
        this.location = location;
        this.currentLocale = this.locales.find((locale) => {
            return this.location.pathname.startsWith(`/${locale}/`);
        });
    }

    stripLocale(path) {
        return path.replace(`/${this.currentLocale}/`, '/');
    }

    resolve(url) {
        return this.currentLocale ? `/${this.currentLocale}${url}` : url;
    }
}

export default new RouteResolver(window.location);
