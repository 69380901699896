import User from 'shared/user';

export const getUserFromToken = (token, getUserSummary) => {
    return Promise.resolve()
        .then(() => {
            if (!token) {
                return new User();
            }

            const user = new User().withToken(token);

            if (getUserSummary && user.loggedIn) {
                return getUserSummary()
                    .then(([tribeDetails, customerOrderSummary, customerInfo]) => {
                        return user.withTribe(tribeDetails).withUserOrderSummary(customerOrderSummary).withCustomerInfo(customerInfo);
                    })
                    .catch(err => {
                        // eslint-disable-next-line no-console
                        console.error(err);
                        return user;
                    });
            }

            return user;
        });
};