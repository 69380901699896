import hash from 'hash.js';

window.dataLayer = [];

export const logPageView = (page, title) => {
    window.dataLayer.push({
        event: 'pageview',
        pageType: page,
        pageTitle: title
    });
};

export const writePageTitleToDataLater = (title) => {
    window.dataLayer.push({
        event: 'pageTitle',
        title: title,
    });
};

export const setDomReady = () => window.dataLayer.push({ event: 'domready' });

export const pushUserDataIntoDataLayer = (user, priceBand, visitorType) => {
    let tierData = 'N/A';
    if (user.loggedIn) {
        tierData = user.tribe && user.tribe.optedIn ? `Level${user.tribe.level}` : 'Non-Tribe';
    }

    window.dataLayer.push({
        event: 'userInfo',
        country: user.countryCode,
        language: user.language,
        customerType: user.userSummary,
        visitorType: visitorType,
        visitorId: user.email ? hash.sha256().update(user.email).digest('hex') : user.guestId,
        email: user.email,
        priceBand: priceBand,
        Tribestatus: user.tribe && user.tribe.optedIn ? 'Tribe' : 'Non-Tribe',
        Tribepoints: user.tribe ? user.tribe.points : 0,
        TribeTier: tierData
    });
};

export const pushBagToDataLayer = (bag) => {
    window.dataLayer.push({
        event: 'currentBasketInfo',
        'bag': bag.map((item) => {
            return { 'item': item.sku, 'quantity': item.quantity, 'price': 0 };
        })
    });
};

export const trackNoSearchResults = (searchQuery) => {
    window.dataLayer.push({
        event: 'searchResults',
        eventCategory: 'Search Results',
        eventAction: 'No Search Results Returned',
        eventLabel: searchQuery,
        numberOfSearchResults: 0
    });
};

export const writeProductToDataLayer = (product) => {
    const { itemPrice = null, originalAmount = null } = product.price || {};

    window.dataLayer.push({
        event: 'productInfo',
        'ProductPrice': itemPrice,
        'productName': product.name,
        pageType: 'pdp'
    });
    window.dataLayer.push({
        event: 'detailView',
        eventCategory: 'ecommerce',
        eventAction: 'Detail View',
        'ecommerce': {
            'detail': {
                'actionField': { 'list': product.category, 'action': 'detail' },
                'products': [{
                    'name': product.name,
                    'id': product.sku,
                    'cid': product.attraqt.id,
                    'price': formatPrice(itemPrice),
                    'wasPrice': formatPrice(originalAmount && originalAmount.substr(1)),
                    'brand': product.brand.name,
                    'category': `${product.category}/${product.attraqt.productType}`,
                    'rawCategory': `${product.category.replace(/\s/g, '').replace('&', 'and')}`,
                    'crmCategory': _getCrmCategory(product.category, product.attraqt.productType),
                    'stock': product.inStock ? 'in Stock' : 'Out of stock'
                }]
            },
            'gtm.uniqueEventId': 275
        }
    });
};

const _getCrmCategory = (category, productType) => {
    const strippedCategory = `${category.replace(/\s/g, '').replace('&', 'and')}`;

    if (!productType)
        return strippedCategory;

    return `${strippedCategory}>${productType.toLowerCase().replace(/\s/g, '').replace('&', 'and')}`;
};

export const trackPdpReviewClicks = (event, action) => {

    window.dataLayer.push({
        event: 'pdpInteraction',
        eventCategory: 'pdp Interactions',
        eventAction: 'Write a Review Vote',
        eventLabel: event.title,
        'productID': event.sku,
        'votebutton': action ? 'positive' : 'negative',
        'gtm.uniqueEventId': 2584
    });
};

export const writeAccordionInteractionToDataLayer = (event) => {
    window.dataLayer.push({
        event: event.name,
        eventCategory: event.category,
        eventAction: event.action,
        eventLabel: event.label,
        'gtm.uniqueEventId': 2584
    });
};

export const writeAddToBagFromPDPToDataLayer = (product, url) => {
    const wasPrice = product.price.originalAmount ? product.price.originalAmount.substr(1, product.price.originalAmount.length) : null;
    window.dataLayer.push({
        event: 'addToBasket',
        eventCategory: 'Add to Basket',
        eventAction: getPagetype(url),
        'ecommerce': {
            'add': {
                'actionField': {
                    'addType': 'Add to Basket',
                    'list': product.category,
                    'action': 'add'
                },
                'quantity': 1,
                'currencyCode': product.attraqt.currency,
                'products': [{
                    'name': product.name,
                    'id': product.sku,
                    'cid': product.attraqt.id,
                    'price': formatPrice(product.price.itemPrice),
                    'wasPrice': formatPrice(wasPrice),
                    'brand': product.brand.name,
                    'category': `${product.category}/${product.attraqt.productType}`
                }]
            }
        },
        'gtm.uniqueEventId': 1860
    });

};

export const trackPDPGalleryInteractions = (sku, index) => {
    window.dataLayer.push({
        event: 'pdpInteraction',
        eventCategory: 'pdp Interactions',
        eventAction: 'Image Gallery',
        eventLabel: index + 1,
        'productID': sku,
        'gtm.uniqueEventId': 258
    });
};

export const writeWishlistButtonInteractionToDataLayer = (page, trackingData, action) => {
    window.dataLayer.push({
        event: `${page}Interaction`,
        eventCategory: `${page} Page Interactions`,
        eventAction: `${action === 'add' ? 'Add to' : 'Remove from'} wishlist`,
        eventLabel: trackingData.sku,
        'gtm.uniqueEventId': 2584

    });
};

export const trackWishlistAddToBagClick = (trackingData) => {
    window.dataLayer.push({
        pageType: 'wishlist',
        event: 'wishlist-addToBasket',
        eventCategory: 'Wishlist Interactions',
        eventAction: 'Wishlist Add To Bag',
        'ecommerce': {
            'add': {
                'actionField': {
                    'addType': 'Add to Basket',
                    'list': trackingData.product.category,
                    'action': 'add'
                },
                'products': [{
                    name: trackingData.product.title,
                    id: trackingData.product.id,
                    cid: trackingData.product.id,
                    price: formatPrice(trackingData.product.price.value),
                    wasPrice: formatPrice(trackingData.product.price.wasValue),
                    brand: trackingData.product.brand,
                    category: getListerProductCategory(trackingData.product),
                    quantity: 1
                }]
            }
        },
        'gtm.uniqueEventId': 1140
    });
};

export const trackFilterInteractions = (url, trackingData) => {
    bbTracking(url, trackingData);
};

export const trackHeaderIconClick = (trackingData) => {
    window.dataLayer.push({
        event: 'Header Icon Click',
        eventAction: trackingData.eventAction
    });
};

export const trackHomepagePosterInView = (poster) => {
    const posterHeading = getPosterHeader(poster);
    window.dataLayer.push({
        pageType: 'homepage',
        event: `${poster.columnSide}view`,
        'ecommerce': {
            'promoView': {
                'promotions': [
                    {
                        'id': `${poster.columnSide}_${posterHeading.text}_${poster.key + 1}`,
                        'name': `${poster.item.subText.text}`,
                        'creative': `${posterHeading.text}_${posterHeading.colour}`,
                        'position': `slot ${poster.key + 1}`
                    }
                ]
            }
        },
        'gtm.uniqueEventId': 234
    });
};

export const trackHomepagePosterClick = (poster) => {
    const heading = getPosterHeader(poster);
    window.dataLayer.push({
        pageType: 'homepage',
        event: `${poster.columnSide}click`,
        'ecommerce': {
            'promoClick': {
                'promotions': [
                    {
                        'id': `${poster.columnSide}_${heading.text}_${poster.key + 1}`,
                        'name': `${poster.item.subText.text}`,
                        'creative': `${heading.text}_${heading.colour}`,
                        'position': `slot ${poster.key + 1}`
                    }
                ]
            }
        },
        'gtm.uniqueEventId': 234
    });
};

export const trackHomepageAddToBagClick = (poster) => {
    const heading = getPosterHeader(poster);
    window.dataLayer.push({
        pageType: 'homepage',
        event: `homepage-poster-add-to-bag`,
        'ecommerce': {
            'promoClick': {
                'promotions': [
                    {
                        'id': `${poster.columnSide}_${heading.text}_${poster.key + 1}`,
                        'name': `${heading.text}`,
                        'creative': `${heading.text}_${heading.colour}`,
                        'position': `slot ${poster.key + 1}`,
                        'sku': poster.sku,
                        'quantity': 1
                    }
                ]
            }
        },
        'gtm.uniqueEventId': 234
    });
};

export const trackHomepageButtonClick = (trackingData) => {
    window.dataLayer.push({
        pageType: 'homepage',
        event: `${trackingData.columnSide}ButtonClick`,
        eventCategory: `Homepage ${trackingData.columnSide} Interactions`,
        eventAction: 'Button Click',
        eventLabel: trackingData.text.text
    });
};

export const trackSearchPosterClick = (page, trackingData) => {
    window.dataLayer.push({
        event: 'top-menu-search',
        eventCategory: 'Top Menu Search',
        eventAction: 'Search Banner Clicks',
        eventLabel: trackingData.item.title,
        pageType: page,
        'gtm.uniqueEventId': 2584
    });
};

export const trackSearchPopularSearchesClick = (page, trackingData) => {
    window.dataLayer.push({
        event: 'top-menu-search',
        eventCategory: 'Top Menu Search',
        eventAction: 'Popular Searches Clicks',
        eventLabel: trackingData.link,
        pageType: page,
        'gtm.uniqueEventId': 2584
    });
};

export const trackPLPView = (tree) => {
    window.dataLayer.push({
        event: 'catPageInfo',
        pageType: 'plp',
        'categoryPage': tree.replace('/l/', '')
    });
};

export const trackPLPCategoryBanner = (trackingData) => {

    window.dataLayer.push({
        event: 'catTopBanner',
        eventCategory: 'Category Page Interactions',
        eventAction: 'Top Banner Clicks',
        eventLabel: trackingData.item.callToActionUrl,
        pageType: 'plp',
        'ecommerce': {
            'promoClick': {
                'promotions': [
                    {
                        id: `${trackingData.item.title}_${trackingData.item.description}`,
                        name: `${trackingData.item.title} : ${trackingData.item.description}`,
                        creative: trackingData.item.image.url,
                        position: trackingData.slot
                    }
                ]
            }
        }
    });
};

export const trackPLPAddToBagClick = (trackingData) => {
    window.dataLayer.push({
        pageType: 'plp',
        event: 'catpage-addToBasket',
        eventCategory: 'Category Page Interactions',
        eventAction: 'Category Page Add To Bag',
        'ecommerce': {
            'add': {
                'actionField': {
                    'addType': 'Add to Basket',
                    'list': trackingData.product.category,
                    'action': 'add'
                },
                'products': [{
                    name: trackingData.product.title,
                    id: trackingData.product.id,
                    cid: trackingData.product.id,
                    price: formatPrice(trackingData.product.price.minValue),
                    wasPrice: formatPrice(trackingData.product.price.wasValue),
                    brand: trackingData.product.brand.title,
                    category: getListerProductCategory(trackingData.product),
                    quantity: 1
                }]
            }
        },
        'gtm.uniqueEventId': 1140
    });
};

const getListerProductCategory = (product) => {
    if (!product.category)
        return null;

    if (!product.productType)
        return product.category;

    return `${product.category.toLowerCase()}/${product.productType}`;
};

export const trackPLPLoadMore = (trackingData) => {
    window.dataLayer.push({
        event: 'catPage-LoadMore',
        eventCategory: 'Category Page Interactions',
        eventAction: 'Load More',
        eventLabel: trackingData
    });
};

export const trackPLPProductClick = (trackingData) => {
    let image = trackingData.image.split('/');
    image = image.slice(-1).pop().split('?')[0];
    window.dataLayer.push({
        event: 'catpage-ProductClick',
        eventCategory: 'Category Page Interactions',
        eventAction: 'Product Clicks',
        eventLabel: trackingData.link,
        'imageType': image
    });
};

export const trackPLPCircleNav = (trackingData) => {
    window.dataLayer.push({
        event: 'catPage-StoriesBar',
        eventCategory: 'Category Page',
        eventAction: 'Stories Category Click',
        eventLabel: trackingData.title
    });
};

export const trackMobileMenuClick = (page, trackingData) => {
    window.dataLayer.push({
        event: trackingData.event,
        eventCategory: 'Mobile Menu',
        eventAction: 'Mobile Menu Click',
        eventLabel: trackingData.label,
        pageType: page,
        'gtm.uniqueEventId': trackingData.eventId || 1111
    });
};

export const trackDesktopMenuClick = (page, trackingData) => {
    window.dataLayer.push({
        event: trackingData.event,
        eventCategory: trackingData.category,
        eventAction: trackingData.action,
        eventLabel: trackingData.label,
        pageType: page,
        'gtm.uniqueEventId': trackingData.eventId || 1111
    });
};

export const trackPLPSortInteraction = (page, trackingData) => {
    window.dataLayer.push({
        event: 'sortInteraction',
        eventAction: 'sort',
        eventCategory: 'Filter Interaction',
        eventLabel: trackingData.sortValue,
        pageType: page,
        'gtm.uniqueEventId': 18
    });
};

export const trackPLPFilterInteraction = (page, trackingData) => {
    window.dataLayer.push({
        event: 'filterInteraction',
        eventAction: trackingData.add ? 'Add' : 'Remove',
        eventCategory: 'Filter Interaction',
        eventLabel: trackingData.facetValue,
        pageType: page,
        'gtm.uniqueEventId': 18
    });
};

export const trackNewsletterSignUp = (page) => {
    window.dataLayer.push({
        event: 'newsletter',
        eventCategory: 'Newsletter Sign Up',
        eventAction: 'Connect With Us',
        eventLabel: 'Opted In',
        pageType: page,
        'gtm.uniqueEventId': 8489
    });
};

export const trackPLPFooterMenuClick = (page, trackingData) => {
    window.dataLayer.push({
        event: trackingData.event,
        eventCategory: 'Bottom Menu Navigation',
        eventAction: trackingData.action,
        eventLabel: trackingData.label,
        pageType: page,
        'gtm.uniqueEventId': trackingData.eventId || 1111
    });
};

export const defaultActionTracking = (page, trackingData) => {
    if (trackingData && trackingData.event) {
        window.dataLayer.push({
            event: trackingData.event,
            eventCategory: trackingData.eventCategory,
            eventAction: trackingData.action,
            eventLabel: trackingData.label,
            page: page,
            'gtm.uniqueEventId': trackingData.eventId || 1111
        });
    }
};

export const addToBagTracking = (url, page, trackingData, product, sku) => {
    let data;
    if (page === 'POSTER') {
        data = Object.assign({ sku, ...trackingData, event: 'homepage-poster-add-to-bag' });
    }
    if (page === 'PLP') {
        data = Object.assign({ product, event: 'catpage-addToBasket' });
    }
    if (page === 'WISHLIST') {
        data = Object.assign({ product, event: 'wishlist-addToBasket' });
    }

    return bbTracking(url, data);
};

export const bbTracking = (url, trackingData) => {
    if (!trackingData || !trackingData.event) return Promise.resolve();
    return Promise.resolve().then(() => {
        const page = getPagetype(url);
        switch (trackingData.event) {
            case 'add-to-wishlist':
                writeWishlistButtonInteractionToDataLayer(page, trackingData, 'add');
                break;
            case 'remove-from-wishlist':
                writeWishlistButtonInteractionToDataLayer(page, trackingData, 'remove');
                break;
            case 'wishlist-addToBasket':
                trackWishlistAddToBagClick(trackingData);
                break;
            case 'newsletter-sign-up':
                trackNewsletterSignUp(page);
                break;
            case 'header-icon-click':
                trackHeaderIconClick(trackingData);
                break;        
            case 'home-poster':
                trackHomepagePosterClick(trackingData);
                break;
            case 'home-video-poster':
                trackHomepagePosterClick(trackingData);
                break;
            case 'home-poster-scroll':
                trackHomepagePosterClick(trackingData);
                break;
            case 'homepage-button-click':
                trackHomepageButtonClick(trackingData);
                break;
            case 'homepage-poster-add-to-bag':
                trackHomepageAddToBagClick(trackingData);
                break;
            case 'catTopBanner':
                trackPLPCategoryBanner(trackingData);
                break;
            case 'catpage-addToBasket':
                trackPLPAddToBagClick(trackingData);
                break;
            case 'catPage-LoadMore':
                trackPLPLoadMore(trackingData.pageNumber);
                break;
            case 'catpage-ProductClick':
                trackPLPProductClick(trackingData);
                break;
            case 'catPage-StoriesBar':
                trackPLPCircleNav(trackingData);
                break;
            case 'filterInteraction':
                trackPLPFilterInteraction(page, trackingData);
                break;
            case 'sortInteraction':
                trackPLPSortInteraction(page, trackingData);
                break;
            case 'mobileMenuLink':
            case 'mobileMenuViewAllLink':
                trackMobileMenuClick(page, trackingData);
                break;
            case 'top-menu-nav':
                trackDesktopMenuClick(page, trackingData);
                break;
            case 'bottom-menu-nav':
                trackPLPFooterMenuClick(page, trackingData);
                break;
            case 'top-menu-search':
                trackHeaderIconClick(page, trackingData);
                break;
            case 'top-menu-nav-icon':
                trackHeaderIconClick(page, trackingData);
                break;
            case 'search-poster':
                trackSearchPosterClick(page, trackingData);
                break;
            case 'search-popular-searches':
                trackSearchPopularSearchesClick(page, trackingData);
                break;
            default:
                if (trackingData.event && trackingData.eventCategory && trackingData.action && trackingData.label) {
                    defaultActionTracking(trackingData);
                }
                break;
        }
    });

};

export const productImpressionTracking = (productImpressions, list) => {
    productImpressions.length &&
        window.dataLayer.push({
            'event': 'ProductImpression',
            'ecommerce': {
                'currencyCode': productImpressions[0].price.currency,
                'impressions':
                    productImpressions.map(product => {
                        return {
                            'name': product.title,
                            'id': product.id,
                            'cid': product.id,
                            'price': formatPrice(product.price.minValue),
                            'wasPrice': product.price.wasValue ? formatPrice(product.price.wasValue) : null,
                            'brand': product.brand.title,
                            'category': `${product.category}`,
                            'rawCategory': `${product.category.replace(/\s/g, '').replace('&', 'and')}`,
                            'crmCategory': _getCrmCategory(product.category, product.productType),
                            'stock': product.stockStatus,
                            'list': list.replace(/.l\//, '').replace('?q=', 'Search%20-%20').replace(/%20/g, ' '),
                            'position': product.position
                        };
                    })
            }
        });
};

const formatPrice = (price) => {
    return price ? parseFloat(price).toFixed(2) : null;
};

const getPosterHeader = (poster) => {
    if (poster.item.heading) {
        return poster.item.heading;
    }

    return {
        text: 'NO_HEADER_TEXT',
        colour: 'NO_HEADER_COLOUR'
    };
};

const getPagetype = (url) => {

    const baseUrl = url.split('?')[0];

    if (baseUrl.indexOf('/p/') > -1) return 'pdp';
    if (baseUrl.indexOf('/l/') > -1) return 'plp';
    if (baseUrl.indexOf('/s/') > -1) return 'static';
    if (baseUrl.indexOf('/pages/') > -1) return 'static';
    if (baseUrl.indexOf('/account/') > -1) return 'account';
    if (baseUrl.indexOf('/bag/') > -1) return 'bag';
    if (baseUrl.indexOf('/checkout/') > -1) return 'checkout';

    return 'Homepage';
};