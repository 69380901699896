import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared/components/Button/Button';
import { Input } from 'shared/components/Input/Input';

import './InputWithButton.scss';

export const InputWithButton = ({ type, placeholder, value, onChange, onBlur, buttonText, onClick }) => {
    return (
        <div className="form">
            <Input type={type} placeholder={placeholder} value={value} onChange={onChange} onBlur={onBlur} />
            <Button text={buttonText} onClick={onClick} type='secondary' />
        </div>
    );
};

InputWithButton.propTypes = {
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
